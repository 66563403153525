import { Roles } from '@/domain/accounts/roles';
import { useRoleBasedLink } from '@/hooks/use-role-based-link';
import { V2Links } from '@/v2-router/const/links';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import { MenuWrapper } from '../parts/menu-holder/menu-holder';
import S from './on-hold.styles';

interface DashboardLayoutProps {
  children: ReactNode;
}

export const OnHoldLayout = ({ children }: DashboardLayoutProps) => {
  const logoutLabel = useTranslation('ui.logout');

  const { logout } = useLogout();

  const homepageUrl = useRoleBasedLink({
    [Roles.FOUNDER]: V2Links.founderHomepage(),
    [Roles.NED]: V2Links.advisorHomepage(),
    [Roles.INVESTOR]: V2Links.investorHomepage(),
    [Roles.INVESTOR_NED]: V2Links.investorAdvisorHomepage(),
  });

  return (
    <S.Layout>
      <MenuWrapper>
        <S.Header>
          <S.Content>
            <S.DashboardLink to={homepageUrl}>
              <S.Logo />
            </S.DashboardLink>

            <S.Logout type="link" key="logout" onClick={() => logout()}>
              {logoutLabel}
            </S.Logout>
          </S.Content>
        </S.Header>
      </MenuWrapper>
      <S.Content>{children}</S.Content>
    </S.Layout>
  );
};
