import { V2SignOutButton } from '@/components/ui/v2-sign-out-button/v2-sign-out-button';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { V2Links } from '@/v2-router/const/links';
import { Roles } from '@domain/accounts/roles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Auth0Provider } from '../../../types';
import Buttons from '../../parts/buttons/buttons';
import FormHolder from '../../parts/form-holder/form-holder';

import S from './choose-role.form.styles';

export const SESSION_STORAGE_REGISTRATION_KEY = 'registrationData';

type AvailableRegisterRole = Roles.NED | Roles.FOUNDER | Roles.INVESTOR;

export const ChooseRoleForm = ({ registerProvider }: { registerProvider: Auth0Provider }) => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const navigate = useNavigate();

  const [iamLabel, advisorLabel, founderLabel, investorLabel, notAvailableTooltipLL] = useTranslation([
    'register.chooseRole.Iam',
    'register.chooseRole.advisor',
    'register.chooseRole.founder',
    'register.chooseRole.investor',
    'register.provider.xero.buttonTooltip',
  ]);

  useEffect(() => {
    const lastSelectedData = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_KEY);

    if (lastSelectedData) {
      const { role: lastSelectedRole } = JSON.parse(lastSelectedData);
      navigate({ pathname: V2Links.sharedRegisterRole({ role: lastSelectedRole }), search: window.location.search });
    }
  }, []);

  const goToNext = () => {
    if (!selectedRole) return;
    navigate({
      pathname: V2Links.sharedRegisterRole({ role: selectedRole }),
      search: window.location.search,
    });
  };

  const getOptions = (): {
    value: AvailableRegisterRole;
    label: string;
    isSelected: boolean;
    isAvailable: boolean;
  }[] => {
    const allRoles: AvailableRegisterRole[] = [Roles.NED, Roles.FOUNDER, Roles.INVESTOR];

    const providerAvailableRoles: Record<Auth0Provider, AvailableRegisterRole[]> = {
      auth0: allRoles,
      ['google-oauth2']: allRoles,
      linkedin: allRoles,
      ['xero-oauth2']: [Roles.FOUNDER],
    };

    return allRoles.map((role) => {
      const roleToLabelMap: Record<AvailableRegisterRole, string> = {
        [Roles.NED]: advisorLabel,
        [Roles.FOUNDER]: founderLabel,
        [Roles.INVESTOR]: investorLabel,
      };

      return {
        value: role,
        label: roleToLabelMap[role],
        isSelected: selectedRole === role,
        isAvailable: providerAvailableRoles[registerProvider].some((r) => r === role),
      };
    });
  };

  return (
    <>
      <FormHolder data-testid="form-holder">
        {getOptions().map((item) => (
          <S.ButtonWrapper
            key={item.value}
            data-testid={`select-button-${item.value}`}
            type="button"
            disabled={item.isAvailable === false}
            $selected={item.isSelected}
            onClick={() => setSelectedRole(item.value)}
          >
            <S.CheckedIndicator>{item.isSelected && <S.CheckMarkIcon />}</S.CheckedIndicator>
            <S.RoleLabel>
              {iamLabel}
              {item.label}
            </S.RoleLabel>

            {item.isAvailable === false && (
              <S.TooltipWrapper>
                <V2Tooltip tooltipText={notAvailableTooltipLL} color="currentColor" />
              </S.TooltipWrapper>
            )}
          </S.ButtonWrapper>
        ))}

        <Buttons data-testid="continue-button" disabled={!selectedRole} hideBackButton onClick={goToNext} />
      </FormHolder>

      <V2SignOutButton />
    </>
  );
};
