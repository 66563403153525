import { metricIdQueryParam } from '@/pages/content/pulse/constants';
import { ChartTimePeriod, getPeriodOption, Periods } from '@parts/periods/periods';
import { LazyReactApexChart } from '@parts/react-apex-chart/react-apex-chart.lazy';
import { theme as themeConfig } from '@styles/theme-config';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { dateFromYearAndMonth } from '@utils/fns/date-from-year-and-month';
import { useChartSeries } from '@utils/hooks/use-chart-series/use-chart-series';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import type { ApexOptions } from 'apexcharts';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { nodeIdQueryParam } from '../row/row';

import S from './live-chart.styles';

export type Node = {
  year: number;
  month: number;
  value: number | null;
  forecast: number | null;
  note: string | null;
  id: string;
};

export type ChartNode = (string | number | null)[];

type PossibleChartNode = ChartNode | undefined;

interface LiveChartProps {
  nodes: Node[];
  showPeriods?: boolean;
}

export const LiveChart = ({ nodes, showPeriods = true }: LiveChartProps) => {
  const [period, setPeriod] = useState(ChartTimePeriod.MAX);

  const { [nodeIdQueryParam]: selectedNodeId, [metricIdQueryParam]: metricId } = useQueryParams() as {
    [key: string]: string;
  };

  const { rawValueSeries, rawForecastSeries, previewSeries } = useChartSeries({
    scale: 0,
    nodes: [...nodes],
  });

  const annotations = useMemo(() => {
    const getMarker = (color: string) => ({
      size: 8,
      fillColor: color,
      strokeColor: themeConfig.baseColors.White,
      radius: 2,
    });

    const getX = (node: PossibleChartNode) =>
      node && node[1] ? DateTime.fromISO(node[1].toString()).toMillis() : undefined;
    const getY = (node: PossibleChartNode) => (node && node[2] ? Number(node[2]) : undefined);

    const selectedValue = rawValueSeries.find(([id]) => id === selectedNodeId);
    const selectedForecast = rawForecastSeries.find(([id]) => id === selectedNodeId);

    const valueX = getX(selectedValue);
    const forecastX = getX(selectedForecast);

    return {
      xaxis: [{ x: valueX || forecastX }],
      points: [
        {
          x: valueX,
          y: getY(selectedValue),
          marker: getMarker(themeConfig.baseColors.Blue200),
        },
        {
          x: forecastX,
          y: getY(selectedForecast),
          marker: getMarker(themeConfig.baseColors.Red),
        },
      ],
    };
  }, [selectedNodeId, rawValueSeries, rawForecastSeries]);

  const activeNodeDate = useMemo(() => {
    const node = nodes.find((n) => n.id === selectedNodeId);

    if (!node) return;

    return dateFromYearAndMonth(node.year, node.month);
  }, [selectedNodeId, nodes]);

  const getBoundary = (type: 'past' | 'future') => {
    if (!activeNodeDate) return undefined;

    const offsettingFn = type === 'past' ? 'minus' : 'plus';

    if (period === ChartTimePeriod.SIX_MONTHS) {
      return activeNodeDate[offsettingFn]({ months: 3, days: 15 }).toMillis();
    }

    if (nodes.filter((n) => n.forecast || n.value).length === 1) {
      return activeNodeDate[offsettingFn]({ months: 1, days: 15 }).toMillis();
    }
  };

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
        autoScaleYaxis: false,
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    annotations,
    xaxis: {
      min: getBoundary('past'),
      max: getBoundary('future'),
      type: 'datetime',
      labels: {
        show: true,
        style: {
          colors: themeConfig.color.metricFont,
          fontSize: themeConfig.fontSize.xxsmall,
          fontFamily: themeConfig.fontFamily.primary,
          fontWeight: themeConfig.fontWeight.regular,
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,
        offsetX: -10,
        formatter: (value: number) => currencyToShortFormat(value) || '0',
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0,
        opacityTo: 0,
      },
    },
    markers: {
      size: [0.5, 0.5],
      colors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
      strokeColors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
      strokeWidth: 2,
    },
    tooltip: { enabled: false },
    stroke: {
      curve: 'smooth',
      width: [4, 2],
      colors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
    },
    grid: {
      show: true,
      borderColor: themeConfig.color.chartBlueColor,
      strokeDashArray: 10,
    },
  };

  return (
    <S.Wrapper>
      <LazyReactApexChart height={165} options={options} series={previewSeries} />
      {showPeriods && (
        <Periods
          metricId={metricId}
          series={previewSeries}
          setActive={setPeriod}
          active={selectedNodeId ? period : ChartTimePeriod.MAX}
          options={[
            getPeriodOption(ChartTimePeriod.SIX_MONTHS, '6M', '-3M/+3M', !selectedNodeId),
            getPeriodOption(ChartTimePeriod.MAX, 'MAX', 'All'),
          ]}
        />
      )}
    </S.Wrapper>
  );
};
