import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useLogout } from '@/utils/hooks/use-logout/use-logout';
import type { CSSProperties } from 'react';
import { V2Button } from '../v2-button/v2-button';

export const V2SignOutButton: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const { logout } = useLogout();
  const [signOut] = useTranslation(['ui.logout']);

  return (
    <V2Button
      style={style}
      variant="link"
      onClick={() => logout()}
      isPaddingDisabled
      isAlwaysStretch
      resetStylesProps={{ ...style, marginTop: 25 }}
    >
      <V2Button.Text style={{ color: '#616A71' }} isBold isUnderlined>
        {signOut}
      </V2Button.Text>
    </V2Button>
  );
};
