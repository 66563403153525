import { type AuthorizationParams, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { V2Routes } from '@/v2-router/const/routes';
import S from './unauthenticated-redirect.styles';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';

interface Props {
  params: AuthorizationParams;
}
export const UnauthenticatedRedirect = ({ params }: Props) => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  if (!isAuthenticated && !user && !isLoading) {
    loginWithRedirect({
      authorizationParams: params,
    });
  } else if (!isLoading) {
    navigate(V2Routes.ROOT, { replace: true });
  }

  return (
    <S.SpinnerWrapper>
      <FullHeightSpinner />
    </S.SpinnerWrapper>
  );
};
