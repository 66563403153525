import { useRoutes } from 'react-router-dom';
import { V2Routes } from './const/routes';
import { RootLayout } from './layouts/root-layout';

import { adminRoutes } from './routes/admin-routes';
import { advisorRoutes } from './routes/advisor-routes';
import { errorsRoutes } from './routes/errors-routes';
import { founderRoutes } from './routes/founder-routes';
import { investorAdvisorRoutes } from './routes/investor-advisor-routes';
import { investorRoutes } from './routes/investor-routes';
import { legacyRoutes } from './routes/legacy-routes';
import { onboardingRoutes } from './routes/onboarding-routes';
import { restRoutes } from './routes/rest-routes';
import { sharedRoutes } from './routes/shared-routes';

/**********************************************************
 *
 *  ROUTER CONFIGURATION
 */
export const Routes: React.FC = () =>
  useRoutes([
    {
      path: V2Routes.ROOT,
      element: <RootLayout />,
      children: [
        ...sharedRoutes,
        ...onboardingRoutes,
        ...founderRoutes,
        ...investorRoutes,
        ...advisorRoutes,
        ...investorAdvisorRoutes,
        ...errorsRoutes,
        ...adminRoutes,
        ...restRoutes,

        ...legacyRoutes,
      ],
    },
  ]);
