import { SubscriptionProductStatus } from '@/domain/accounts/subscription-products';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { Navigate, useLocation } from 'react-router-dom';
import { V2Routes } from '../const/routes';

export const HandleRootRoute = () => {
  const location = useLocation();
  const { state } = useUserAccount();
  const { isAuthorized, products } = state;

  const isPaid = products.some((product) =>
    [SubscriptionProductStatus.APPROVED, SubscriptionProductStatus.WAITING_FOR_APPROVAL].includes(product.status),
  );

  const targePath = () => {
    if (isAuthorized && isPaid) return V2Routes.SHARED_START;
    if (isAuthorized) return V2Routes.SHARED_CHECKOUT;
    return V2Routes.SHARED_LOGIN;
  };

  return <Navigate to={{ pathname: targePath(), search: location.search }} replace />;
};
