import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { AccountDataResponse } from './account-data.types';

export const ACCOUNT_DATA_CACHE_KEY = 'account-data';

export const getAccountData = async (): Promise<GraphqlResponse<AccountDataResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
        query getAccountData {
          account {
            integrations {
              codat {
                isConnected
                isFirstSyncCompleted
              }
              crm {
                id
              }
              stripe {
                customerId
              }
            }
            id
            email
            role
            products {
              type
              status
              discoverable
            }
            permissions {
              name
              allowed
            }
            avatarUrl
            name
            businessName
            status
            auth0Provider
            currency
            countryOfResidence
            onboardingStatus {
              founder
              investor
              ned
              founderQualifyingQuestions
            }
            group
          }
        }
    `,
  });

  return graphqlResponseWrapper(data, ['account']);
};
