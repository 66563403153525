import SuccessIcon from '@assets/icons/investor-onboarding/success.svg?react';
import { contactHelpConnectdEmail } from '@constants/emails';
import { Roles } from '@domain/accounts/roles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';

import { useRoleBasedLink } from '@/hooks/use-role-based-link';
import { V2Links } from '@/v2-router/const/links';
import S from './banner.style';

export const SummaryPageBanner = () => {
  const [title, description, contactText, contactLinkText, returnToProfile] = useTranslation([
    'investor-onboarding.summary.title',
    'investor-onboarding.summary.description',
    'investor-onboarding.summary.contactText',
    'investor-onboarding.summary.contactLinkText',
    'investor-onboarding.summary.nedReturnToProfile',
  ]);

  const {
    state: { userRole },
  } = useUserAccount();

  const profileUrl = useRoleBasedLink({
    [Roles.INVESTOR]: V2Links.investorProfile(),
    [Roles.NED]: V2Links.advisorProfile(),
    [Roles.INVESTOR_NED]: V2Links.investorAdvisorProfile({ role: 'investor' }),
  });

  return (
    <S.Wrapper data-testid="investor-summary-page-banner">
      <SuccessIcon />
      <S.Title data-testid="investor-summary-page-banner-title">{title}</S.Title>
      <S.Description data-testid="investor-summary-page-banner-subtitle">{description}</S.Description>

      <S.Description>
        {contactText}
        <S.SecureLink data-testid="investor-summary-page-banner-link" href={`mailto:${contactHelpConnectdEmail}`} outer>
          {contactLinkText}
        </S.SecureLink>
      </S.Description>

      {userRole === Roles.NED && <S.ProfileReturnLink to={profileUrl}>{returnToProfile}</S.ProfileReturnLink>}
    </S.Wrapper>
  );
};
