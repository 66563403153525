import { useCodatAddIntegrationUrl } from '@/modules/founder/report/hooks/use-codat-add-integration-url';
import { CodatRequestContextValue } from '@/pages/content/pulse/api/connect-codat/connect-codat.action';
import type { ExternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Metrics } from '../../../metrics/metrics';
import { Banner } from './parts/banner/banner';
import { WaitingScreen } from './parts/waiting-screen/waiting-screen';

export const Connected = ({ metrics }: { metrics: ExternalMetric[] }) => {
  const { codatConsoleUrl, isLoadingCodatConsoleUrl } = useCodatAddIntegrationUrl({
    renderContext: CodatRequestContextValue.ReportMetrics,
  });

  const {
    state: {
      integrations: {
        codat: { isFirstSyncCompleted },
      },
    },
  } = useUserAccount();

  if (!codatConsoleUrl || isLoadingCodatConsoleUrl) {
    return <FullHeightSpinner />;
  }

  return (
    <>
      <Banner buttonUrl={codatConsoleUrl} />
      {isFirstSyncCompleted ? <Metrics metrics={metrics} integratedMetricsView /> : <WaitingScreen />}
    </>
  );
};
