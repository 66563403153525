import styled, { css } from 'styled-components';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2ButtonProps } from './v2-button.types';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';

const ResetStyles = styled.div<Partial<V2ButtonProps>>`
  &,
  & > a,
  & > button {
    all: unset;
    display: inline-block;
    text-align: center;
    cursor: default;
    color: initial;
    font-family: initial;
    font-size: initial;
    font-weight: initial;
    line-height: initial;

    ${({ isAlwaysStretch, isStretchOnSmallScreens }) => {
      if (isAlwaysStretch)
        return css`
          & {
            width: 100%;
          }
        `;
      if (isStretchOnSmallScreens)
        return css`
          @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
            & {
              display: ${isStretchOnSmallScreens ? 'block' : 'inline-block'};
              width: ${isStretchOnSmallScreens ? '100%' : 'auto'};
            }
          }
        `;
    }}
  }
`;

const Button = styled.div<Partial<V2ButtonProps>>`
  align-items: center;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 0;
  outline: none;
  overflow: hidden;
  transition:
    filter 150ms ease,
    box-shadow 150ms ease,
    color 150ms ease,
    background-color 150ms ease,
    fill 150ms ease,
    border-color 150ms ease,
    transform 50ms ease;
  text-align: center;
  width: 100%;
  height: 100%;

  ${({ isDisabled }) => {
    if (isDisabled)
      return css`
        cursor: not-allowed;
        filter: brightness(1);
        opacity: 0.48;
      `;
    return css`
      &:hover {
        cursor: pointer;
        filter: brightness(1.04);
      }

      &:active {
        filter: brightness(1.06);
        transform: scale(0.98);
      }

      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: 2px solid
          ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[200] : theme.v2.colors.blue[400])};
      }
      outline-offset: 2px;
    `;
  }}

  ${({ direction }) => {
    if (direction === 'row')
      return css`
        flex-direction: row;
      `;
    if (direction === 'column')
      return css`
        flex-direction: column;
      `;
  }}


${({ theme, buttonSize }) => {
    if (buttonSize === 'auto')
      return css`
        font-size: ${theme.v2.typography.sizes.md};
        padding: 12px 25.5px;
        @media (max-width: ${theme.v2.breakpoints.md}) {
          font-size: ${theme.v2.typography.sizes.sm};
          padding: 8px 16px;
        }
      `;
    if (buttonSize === 'large')
      return css`
        font-size: ${theme.v2.typography.sizes.md};
        padding: 12px 25.5px;
      `;
    if (buttonSize === 'small')
      return css`
        font-size: ${theme.v2.typography.sizes.sm};
        padding: 8px 16px;
      `;
  }}

${({ isPaddingDisabled }) => {
    if (isPaddingDisabled)
      return css`
        border-radius: 0;
        padding: 0 !important;
      `;
  }}

  ${({ theme, variant }) => {
    if (variant === 'primary')
      return css`
        background-color: ${theme.v2.colors.blue[300]};
        color: ${theme.v2.colors.neutral[100]};
      `;
    if (variant === 'secondary')
      return css`
        background-color: ${theme.v2.colors.blue[100]};
        color: ${theme.v2.colors.blue[300]};
      `;
    if (variant === 'selected')
      return css`
        background: ${theme.v2.gradients['blue-gradient']};
        color: ${theme.v2.colors.neutral[50]};
      `;
    if (variant === 'link')
      return css`
        background-color: transparent;
        color: ${theme.v2.colors.blue[300]};
      `;
    if (variant === 'accent')
      return css`
        background-color: ${theme.v2.colors.magenta[400]};
        color: ${theme.v2.colors.neutral[100]};
      `;
    if (variant === 'text')
      return css`
        display: inline-flex;
        padding: 0;
        background: transparent;
        border: 0 none;
        box-shadow: none;
        color: inherit;
      `;
    if (variant === 'outline')
      return css`
        border: 1px solid ${themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[400])};
        background-color: transparent;
        color: ${themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};
      `;
    if (variant === 'outline-success')
      return css`
        border-color: ${theme.v2.colors.green[500]};
        color: ${theme.v2.colors.green[500]};
        fill: ${theme.v2.colors.green[500]};

        &:disabled {
          border-color: ${theme.v2.colors.green[600]};
          color: ${theme.v2.colors.green[600]};
          fill: ${theme.v2.colors.green[600]};
        }
      `;
    if (variant === 'outline-selected')
      return css`
        border: 1px solid ${theme.v2.colors.blue[300]};
        background-color: transparent;
        color: ${themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};
      `;
    if (variant === 'placement')
      return css`
        color: ${theme.v2.colors.neutral[50]};
        background: ${theme.v2.gradients['pink-purple']};
      `;
    if (variant === 'outline-placement')
      return css`
        border: 1px solid ${theme.v2.colors.magenta[500]};
        background-color: transparent;
        color: ${theme.v2.colors.magenta[500]};
      `;
  }}
`;

const Text = styled.span<Pick<V2ButtonProps, 'buttonSize'> & { isBold: boolean; isUnderlined: boolean }>`
  word-break: break-word;
  width: 'auto';
  max-width: 200px;

  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.v2.typography.weights.medium : theme.v2.typography.weights.regular};

  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};

  ${({ theme, buttonSize: size }) => {
    if (size === 'auto')
      return css`
        font-size: ${theme.v2.typography.sizes.sm};
        @media (max-width: ${theme.v2.breakpoints.md}) {
          font-size: ${theme.v2.typography.sizes.xs};
        }
      `;
    if (size === 'large')
      return css`
        font-size: ${theme.v2.typography.sizes.sm};
      `;
    if (size === 'small')
      return css`
        font-size: ${theme.v2.typography.sizes.xs};
      `;
  }}
`;

const Icon = styled(V2Icon)<Pick<V2ButtonProps, 'variant'>>`
  ${({ theme, variant }) => {
    if (variant === 'primary')
      return css`
        color: ${theme.v2.colors.neutral[100]};
        fill: ${theme.v2.colors.neutral[100]};
      `;
    if (variant === 'secondary' || variant === 'link')
      return css`
        color: ${theme.v2.colors.blue[300]};
        fill: ${theme.v2.colors.blue[300]};
      `;
    if (variant === 'outline' || variant === 'outline-selected')
      return css`
        color: ${theme.v2.colors.blue[300]};
        fill: ${theme.v2.colors.blue[300]};
      `;
    if (variant === 'placement')
      return css`
        color: ${theme.v2.colors.neutral[50]};
        fill: ${theme.v2.colors.neutral[50]};
      `;
    if (variant === 'outline-placement')
      return css`
        color: ${theme.v2.colors.magenta[500]};
        fill: ${theme.v2.colors.neutral[500]};
      `;
  }}
`;

export default { ResetStyles, Button, Icon, Text };
