import { Outlet, type RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { LoginAsUserPage } from '@/pages/auth/login-as-user/login-as-user.page';

export const adminRoutes: RouteObject[] = [
  {
    element: <Outlet />,
    children: [
      {
        path: V2Routes.ADMIN_LOGIN_AS_USER,
        element: <LoginAsUserPage />,
      },
    ],
  },
];
