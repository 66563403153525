import { FullHeightSpinner } from '@/parts/full-height-spinner/full-height-spinner';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const RootLayout = () => {
  return (
    <Suspense fallback={<FullHeightSpinner />}>
      <Outlet />
    </Suspense>
  );
};
