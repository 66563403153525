import type { Roles } from '@/domain/accounts/roles';
import type { SubscriptionProduct } from '@/domain/accounts/subscription-products';

// required permissions to access a route true
// true = required true, false = required false, undefined = open
export type PermissionStatus = boolean | undefined;

export const getEvalPermissionFn =
  (role: Roles, products: SubscriptionProduct[]) =>
  (
    permission: PermissionStatus | ((role: Roles, products: SubscriptionProduct[]) => PermissionStatus),
    status: boolean,
  ): boolean => {
    const perm = typeof permission === 'function' ? permission(role, products) : permission;

    if (perm === true) return status;
    if (perm === false) return !status;
    if (perm === undefined) return true;
    return false;
  };
