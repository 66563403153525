import { V2Button } from '@/components/ui/v2-button/v2-button';
import { usePeriodicNotification } from '@/hooks/use-periodic-notification';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useTour } from '@/utils/tour/use-tour';
import { useEffect } from 'react';

export const NOT_READY_YET_TOUR_KEY = 'advisor-homepage-you-are-hidden';
const NOT_READY_YET_TOUR_DAYS = 7;
const SHOW_ACTION_DELAY = 500;

/**
 * Custom hook that provides functionality for displaying a tour reminder when the user is hidden.
 *
 * @param {Object} options - The options for the hook.
 * @param {boolean} [options.enabled=true] - Whether the tour reminder is enabled or not (use it to decide when show reminder).
 *
 * @returns {Object} - An object containing the element IDs to attach the tour steps to.
 */
export const useYouAreHiddenReminder = ({
  enabled = true,
  onPrimaryActionClick,
}: {
  enabled?: boolean;
  onPrimaryActionClick: () => void;
}) => {
  const [titleLL, descriptionLL, actionButtonMainLL, actionButtonSecondaryLL] = useTranslation([
    'homepage.advisor.youAreHidden.popup.title',
    'homepage.advisor.youAreHidden.popup.description',
    'homepage.advisor.youAreHidden.popup.actionButtonMain',
    'homepage.advisor.youAreHidden.popup.actionButtonSecondary',
  ]);

  const { isNotificationVisible: showNotReadyYetTour, hideNotification: closeNotReadyYetTourNotification } =
    usePeriodicNotification(NOT_READY_YET_TOUR_KEY, { days: NOT_READY_YET_TOUR_DAYS });

  const { stop, start } = useTour([
    {
      selector: '[data-tour="advisor-homepage-you-are-hidden"]',
      position: 'right',
      title: titleLL,
      description: descriptionLL,
      footer: (
        <>
          <V2Button
            data-testid="advisor-homepage-you-are-hidden-tour-action-button-primary"
            onClick={() => {
              closeNotReadyYetTourNotification();
              stop();
              onPrimaryActionClick();
            }}
          >
            <V2Button.Text isBold>{actionButtonMainLL}</V2Button.Text>
          </V2Button>
          <V2Button
            variant="text"
            data-testid="advisor-homepage-you-are-hidden-tour-action-button-secondary"
            onClick={() => {
              closeNotReadyYetTourNotification();
              stop();
            }}
          >
            <V2Button.Text>{actionButtonSecondaryLL}</V2Button.Text>
          </V2Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    if (!enabled || !showNotReadyYetTour) return;
    start({ delay: SHOW_ACTION_DELAY });
  }, [enabled, showNotReadyYetTour]);

  return {
    targetElementId: 'advisor-homepage-you-are-hidden',
  };
};
