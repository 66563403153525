import {
  AccountStatus,
  userAccountInitialState,
  type UserAccountMetadata,
  type UserAccountState,
} from '@context/user/user-account.context';
import { getRoleFromProducts } from '@domain/accounts/get-role-from-products';
import { Roles } from '@domain/accounts/roles';
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
import type { RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';

export interface UserAccountAction {
  type: UserActions;
  initialized?: boolean;

  userId?: string;
  userRole?: Roles;
  metadata?: UserAccountMetadata;
  products?: SubscriptionProduct[];
  status?: AccountStatus;
  onboarding?: Partial<RoleOnboardingStatus>;
}

export enum UserActions {
  SET_INITIALIZED = 'set-initialized',
  START_LOGIN = 'start-login',
  FINISH_LOGIN = 'finish-login',
  START_LOGOUT = 'start-logout',
  FINISH_LOGOUT = 'finish-logout',
  SET_METADATA = 'set-metadata',
  SET_PRODUCTS = 'set-products',
  SET_USER_ONBOARDING_STATUS = 'set-user-onboarding-status',
}

export const reducer = (state: UserAccountState, action: UserAccountAction): UserAccountState => {
  switch (action.type) {
    case UserActions.SET_INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    case UserActions.FINISH_LOGIN:
      return {
        ...state,
        ...{
          isAuthorized: true,
          isLoggingIn: false,
          userId: action.userId || state.userId,
          userRole: action.userRole || state.userRole,
        },
      };

    case UserActions.START_LOGIN:
      return { ...userAccountInitialState, ...{ isLoggingIn: true } };

    case UserActions.START_LOGOUT:
      return { ...state, ...{ isLoggingOut: true } };

    case UserActions.FINISH_LOGOUT:
      return {
        ...userAccountInitialState,
        ...{ initialized: true, isLoggingOut: false },
      };

    case UserActions.SET_METADATA:
      return {
        ...state,
        ...action.metadata,
      };

    case UserActions.SET_PRODUCTS: {
      const products = action.products ?? [];

      return {
        ...state,
        products,
        userRole: products.length > 0 ? getRoleFromProducts(products) : state.userRole,
      };
    }

    case UserActions.SET_USER_ONBOARDING_STATUS: {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...action.onboarding,
        },
      };
    }

    default:
      return state;
  }
};
