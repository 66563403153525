export const colors = {
  blue: {
    50: '#ECEDFF',
    100: '#D9E3FE',
    200: '#B3B9FD',
    300: '#4050FB',
    400: '#10328E',
    500: '#1B2748',
    600: '#00144A',
    700: '#0E1731',
    800: '#070F25',
  },
  red: {
    50: '#FEEBEF',
    100: '#FFC0CC',
    200: '#FF6886',
    300: '#F73B60',
    400: '#D5284A',
    500: '#B31837',
    600: '#6F0419',
    700: '#4D000F',
  },
  magenta: {
    50: '#FFE7F4',
    100: '#FFBDE1',
    200: '#FF93CE',
    300: '#FF3FA8',
    400: '#E6007E',
    500: '#BD0068',
    600: '#940051',
    700: '#6C003B',
    800: '#2B0012',
  },
  green: {
    50: '#F6FFF7',
    100: '#D0FFD2',
    200: '#A9FDAC',
    300: '#93E396',
    400: '#7EC981',
    500: '#58965B',
    600: '#366438',
    700: '#274A28',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#F8F8FB',
    200: '#F7F7F7',
    300: '#E0E0E0',
    400: '#B0B4B8',
    500: '#616A71',
    600: '#4A4A4A',
    700: '#070F25',
    800: '#000000',
  },
  notifications: {
    'info-dark': '#3E60EB',
    'info-light': '#D9E3FE',
    'warning-dark': '#FFA800',
    'warning-light': '#FFFAEF',
  },
} as const;

export type V2Colors = typeof colors;
