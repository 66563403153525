import CheckMarkIconBase from '@assets/illustrations/auth/checkmark.svg?react';
import styled from 'styled-components';

const RoleLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const ButtonWrapper = styled.button<{ $selected?: boolean }>`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.default};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.registerColors.selectRoleButtonBorder};
  padding: ${({ theme }) => theme.spacing.xmedium} 0;
  background: ${({ $selected, theme }) =>
    $selected ? theme.gradient.pink : theme.registerColors.selectRoleButtonBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: ${({ theme }) => theme.spacing.small} 0;
  max-width: 410px;
  width: 100%;
  height: 44px;

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 410px;
  }
`;

const CheckedIndicator = styled.div`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  border: 1px solid ${({ theme }) => theme.registerColors.indicatorBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.registerColors.background};
`;

const Form = styled.div`
  padding: 40px 60px;
  padding: ${({
    theme: {
      spacing: { base, large },
    },
  }) => `${base} ${large}`};
`;

const CheckMarkIcon = styled(CheckMarkIconBase)`
  color: ${({ theme }) => theme.registerColors.checkIcon};
  width: 11px;
  height: 11px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.small};
  transform: translateY(-50%);
`;

export default {
  ButtonWrapper,
  CheckedIndicator,
  Form,
  CheckMarkIcon,
  RoleLabel,
  TooltipWrapper,
};
