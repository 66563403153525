import { safeParseJson } from '@utils/fns/safe-parse-json';
import { honeybadger } from '../honeybadger';
import type { BaseNotice } from './notifications';

const LS_ERROR_NOTICE_KEY = 'ERROR_NOTICE';

export const storeErrorNoticeInLocalStorage = (notice: BaseNotice) => {
  window.localStorage.setItem(LS_ERROR_NOTICE_KEY, JSON.stringify(notice));
};

export const readAndSendErrorNoticeFromLocalStorage = (): BaseNotice | null => {
  let parsedNotice: BaseNotice | null = null;
  const lsNotice = window.localStorage.getItem(LS_ERROR_NOTICE_KEY);

  if (lsNotice) {
    parsedNotice = safeParseJson(lsNotice, null) as BaseNotice;

    if (parsedNotice) {
      honeybadger.notify(parsedNotice);
    }
  }

  window.localStorage.removeItem(LS_ERROR_NOTICE_KEY);

  return parsedNotice;
};
