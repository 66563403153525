import { Roles } from '@/domain/accounts/roles';
import { V2Links } from '../../../const/links';
import { redirectConfig } from '../../../const/redirect-from-guard';
import type { V2Routes } from '../../../const/routes';

type PermissionStatus = {
  corOK: boolean;
  roleOK: boolean;
  activeOK: boolean;
  onboardingOK: boolean;
  apiPermissionsOK: boolean;
};

export const getRedirectUrl = ({
  role,
  route,
  params,
  permissionStatus,
}: {
  role: Roles;
  route: V2Routes | null;
  params: { [key: string]: string };
  permissionStatus: PermissionStatus;
}): string => {
  const { corOK, roleOK, activeOK, onboardingOK, apiPermissionsOK } = permissionStatus;

  if (!route) {
    return V2Links.error404();
  }

  const routeRedirect = redirectConfig[route][role];
  if (!routeRedirect) {
    console.error(`Cannot find routeRedirect for route: ${route} and role: ${role}`);
    return V2Links.error404();
  }

  if (!corOK) return routeRedirect.invalidCorValue(params as never);
  if (!activeOK) return routeRedirect.invalidActiveValue(params as never);
  if (!roleOK) return routeRedirect.invalidRoleValue(params as never);
  if (!onboardingOK) return routeRedirect.invalidOnboardValue(params as never);
  if (!apiPermissionsOK) return routeRedirect.invalidApiPermissionsValue(params as never);

  return V2Links.error404();
};
