import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './app-version-modal.styles';
import { V2Button } from '@/components/ui/v2-button/v2-button';

type AppResetModalProps = {
  isOpen: boolean;
  close: () => void;
};

// TODO Remove/Replace this with generic Confirm Modal when designs are done
export const AppResetModal = ({ close, isOpen }: AppResetModalProps) => {
  const [title, description, updateLabel, cancelLabel] = useTranslation([
    'app.reset.modal.title',
    'app.reset.modal.description',
    'app.reset.modal.button.update',
    'app.reset.modal.button.cancel',
  ]);

  return (
    <V2Modal
      isOpen={isOpen}
      onCancel={close}
      closable={false}
      title={<S.Title>{title}</S.Title>}
      footer={
        <S.Wrapper>
          <S.Description>{description}</S.Description>
          <S.CancelButton onClick={close} variant="link">
            {cancelLabel}
          </S.CancelButton>
          <V2Button onClick={() => window.location.reload()} variant="selected">
            <V2Button.Text isBold>{updateLabel}</V2Button.Text>
          </V2Button>
        </S.Wrapper>
      }
      size="sm"
    ></V2Modal>
  );
};
