import { useRoleBasedLink } from '@/hooks/use-role-based-link';
import { V2Links } from '@/v2-router/const/links';
import { registrationLink } from '@constants/links';
import { Roles } from '@domain/accounts/roles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';

import S from './header.styles';

export const Header = ({ companyId }: { companyId: string }) => {
  const {
    state: { userRole },
  } = useUserAccount();

  const [sloganLabel, signUpLabel, founderPageLabel] = useTranslation([
    'progress.report.slogan',
    'progress.report.singup',
    'progress.report.founderPage',
  ]);

  const founderProfileUrl = useRoleBasedLink({
    [Roles.INVESTOR]: V2Links.investorPublicProfileFounder({ mode: 'details', id: companyId }),
    [Roles.NED]: V2Links.advisorPublicProfileFounder({ mode: 'details', id: companyId }),
    [Roles.INVESTOR_NED]: V2Links.investorPublicProfileFounder({ mode: 'details', id: companyId }),
  });

  const linkConfig =
    userRole === Roles.NO_ROLE
      ? { route: registrationLink, label: signUpLabel }
      : { route: founderProfileUrl, label: founderPageLabel };

  return (
    <S.Wrapper>
      <S.Content>
        <S.Brand>
          <S.Logo />
          <S.LogoSquare />
          <S.Slogan>{sloganLabel}</S.Slogan>
        </S.Brand>
        {userRole !== Roles.FOUNDER && (
          <S.SecureLink href={linkConfig.route} outer={userRole === Roles.NO_ROLE}>
            <S.GoTo type="primary" disabled={!companyId}>
              {linkConfig.label}
              <S.ArrowRight />
            </S.GoTo>
          </S.SecureLink>
        )}
      </S.Content>
    </S.Wrapper>
  );
};
