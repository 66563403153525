import { getRole } from '@/domain/accounts/get-role';
import { PaymentInfoContainer } from '@/pages/payments/payment-info.page.styles';
import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { V2Links } from '@/v2-router/const/links';
import { setUserMetadata, setUserProducts } from '@context/user/user-account.actions';
import { AccountStatus } from '@context/user/user-account.context';
import { createProductFromValue } from '@domain/accounts/subscription-products';
import { CenterBlock } from '@layout/center-block/center-block.layout';
import { PatternLayout } from '@layout/pattern/pattern.layout';
import { getAccountData } from '@pages/auth/api/account-data/account-data.actions';
import { CircleLoader } from '@pages/payments/parts/circle-loader/circle-loader';
import BigBrandHeading from '@parts/big-brand-heading/big-brand-heading';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export const PaymentInProgressPage = () => {
  useEnforceTheme('dark');

  const [titleLabel, descriptionLabel] = useTranslation(['paymentInProgress.title', 'paymentInProgress.description']);

  const [isPaid, setIsPaid] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const { state, dispatch: userAccountDispatch } = useUserAccount();

  const { data: response, refetch } = useQuery(['USER_PRODUCTS'], getAccountData, {
    refetchInterval: 2000,
  });

  useEffect(() => {
    if (!response || !response?.data) return;

    const newProducts = response.data.products.map(createProductFromValue);
    const productsAvailable = Boolean(newProducts?.length);
    const productsUpdated = JSON.stringify(state.products.sort()) !== JSON.stringify(newProducts?.sort());

    setIsPaid(productsAvailable && productsUpdated);

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    let timer: any;

    if (newProducts && productsAvailable && productsUpdated) {
      timer = setTimeout(() => {
        refetch().then((resp) => {
          if (!resp || !resp.data?.data) return;

          const { status, permissions, products, role } = resp.data.data;

          userAccountDispatch(
            setUserMetadata({
              ...state,
              status,
              userRole: getRole(products, role),
              permissions,
            }),
          );
          userAccountDispatch(setUserProducts(newProducts));

          if (status === AccountStatus.ACTIVE) {
            setShouldRedirect(true);
          }
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, userAccountDispatch]);

  return shouldRedirect ? (
    <Navigate to={V2Links.sharedCheckoutSuccess()} />
  ) : (
    <PatternLayout>
      <CenterBlock gradient>
        <PaymentInfoContainer data-testid="payment-loader-container">
          <CircleLoader checked={isPaid} />
          <BigBrandHeading>{titleLabel}</BigBrandHeading>
          <p>{descriptionLabel}</p>
        </PaymentInfoContainer>
      </CenterBlock>
    </PatternLayout>
  );
};
