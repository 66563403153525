import type { Any } from '@/types';
import React from 'react';

export const withLazy = <T extends React.ComponentType<Any>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K,
): I => {
  return Object.create({
    [name]: React.lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
};
