import { axiosInstance, FILE_UPLOAD_TIMEOUT } from '@utils/axios/axios-instance';

export interface IUploadProfileAvatar {
  formData: FormData;
}

export const uploadProfileAvatar = ({ formData }: IUploadProfileAvatar) =>
  axiosInstance.post('/profile/investor/avatar', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    timeout: FILE_UPLOAD_TIMEOUT,
  });
