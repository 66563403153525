import type { ColorThemeMode } from '@/context/theme/app-theme.context';
import { getLsThemeKey } from '@/context/theme/app-theme.provider';
import { FullHeightSpinner } from '@/parts/full-height-spinner/full-height-spinner';
import { withLazy } from '@/v2-router/utils/with-lazy';
import { LocaleProvider } from '@context/locale/locale.provider';
import { GlobalStyles } from '@styles/global.styles';
import { ResetStyles } from '@styles/reset.styles';
import { darkThemeColors, lightThemeColors, theme as themeBase } from '@styles/theme-config';
import { Suspense, useEffect, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const { InternalServerErrorPage } = withLazy(
  () => import('../internal-server/internal-server.page'),
  'InternalServerErrorPage',
);

const useThemeWithoutProvider = () => {
  const [mode, setMode] = useState<ColorThemeMode>('light');

  useEffect(() => {
    const foundTheme = window.localStorage.getItem(getLsThemeKey());
    setMode(foundTheme === 'dark' ? 'dark' : 'light');
  });

  const color = mode === 'dark' ? darkThemeColors : lightThemeColors;
  return { theme: { ...themeBase, color, mode } };
};

export const CriticalErrorPage = () => {
  const { theme } = useThemeWithoutProvider();

  return (
    <LocaleProvider>
      <StyledThemeProvider theme={theme}>
        <ResetStyles />
        <GlobalStyles />
        <Suspense fallback={FullHeightSpinner}>
          <InternalServerErrorPage critical />
        </Suspense>
      </StyledThemeProvider>
    </LocaleProvider>
  );
};
