import { PermissionNames, type ApiDefinedPermission } from '@/pages/auth/api/account-data/account-data.types';
import { V2Routes } from '../../../const/routes';

export const checkApiPermissions = (permissions: ApiDefinedPermission[], route: V2Routes): boolean => {
  const getStatus = (name: PermissionNames) => Boolean(permissions.find((p) => p.name === name)?.allowed);

  const apiPermissionsMap: Partial<Record<V2Routes, boolean>> = {
    [V2Routes.INVESTOR_PORTFOLIO]: getStatus(PermissionNames.Portfolio),
    [V2Routes.INVESTOR_ADVISOR_PORTFOLIO]: getStatus(PermissionNames.Portfolio),
  };

  return apiPermissionsMap[route] ?? false;
};
