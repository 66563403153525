import { Roles } from '@/domain/accounts/roles';
import type { RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withGuard } from '../utils/with-guard/with-guard';
import { withLazy } from '../utils/with-lazy';

/**********************************************************
 *  LAZY LOADING COMPONENTS
 */
const { InvestorLayout } = withLazy(() => import('../layouts/investor-layout'), 'InvestorLayout');

const { InvestorStartPage } = withLazy(
  () => import('@/pages/content/start/investor/investor-start-page'),
  'InvestorStartPage',
);
const { InvestorProfilePage } = withLazy(
  () => import('@/pages/content/profile/investor/investor-profile.page'),
  'InvestorProfilePage',
);

const { InvestorMessaging } = withLazy(
  () => import('@/pages/content/messaging/investor/investor-messaging.page'),
  'InvestorMessaging',
);

const { InvestorNotifications } = withLazy(
  () => import('@/pages/content/notifications/investor/investor-notifications.page'),
  'InvestorNotifications',
);

const { InvestorLensPage } = withLazy(
  () => import('@/pages/content/lens/investor/investor-lens.page'),
  'InvestorLensPage',
);

const { FounderPublicPage } = withLazy(
  () => import('@/pages/content/public-profile/founder/founder.page'),
  'FounderPublicPage',
);

const { InvestorPortfolio } = withLazy(
  () => import('@/pages/content/portfolio/investor/investor-portfolio.page'),
  'InvestorPortfolio',
);

const { ExpandView } = withLazy(
  () => import('@/pages/content/portfolio/investor/expand-view/expand-view.page'),
  'ExpandView',
);

const { InvestorPulsePage } = withLazy(
  () => import('@/pages/content/pulse/investor/investor-pulse.page'),
  'InvestorPulsePage',
);

const { CompanyDetailsPage } = withLazy(
  () => import('@/pages/content/company-details/company-details.page'),
  'CompanyDetailsPage',
);

const { InvestmentConfirmationPage } = withLazy(
  () => import('@/pages/content/investment-confirmation/investment-confirmation.page'),
  'InvestmentConfirmationPage',
);

/**********************************************************
 *  GUARDED COMPONENTS
 */
const GuardedInvestorLayout = withGuard(InvestorLayout, {
  roles: [Roles.INVESTOR],
  isActive: true,
  isOnboard: true,
  hasCor: true,
});

const GuardedPortfolioPage = withGuard(InvestorPortfolio, {
  apiPermissions: true,
});

export const investorRoutes: RouteObject[] = [
  {
    path: V2Routes.INVESTOR_ROOT,
    element: <GuardedInvestorLayout />,
    children: [
      { path: V2Routes.INVESTOR_HOMEPAGE, element: <InvestorStartPage /> },
      { path: V2Routes.INVESTOR_PROFILE, element: <InvestorProfilePage /> },
      { path: V2Routes.INVESTOR_MESSAGES, element: <InvestorMessaging /> },
      { path: V2Routes.INVESTOR_NOTIFICATIONS, element: <InvestorNotifications /> },
      { path: V2Routes.INVESTOR_LENS, element: <InvestorLensPage /> },
      { path: V2Routes.INVESTOR_PUBLIC_PROFILE_FOUNDER, element: <FounderPublicPage /> },
      { path: V2Routes.INVESTOR_PORTFOLIO, element: <GuardedPortfolioPage /> },
      { path: V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW, element: <ExpandView /> },
      { path: V2Routes.INVESTOR_PORTFOLIO_EXPANDED_VIEW_SINGLE, element: <ExpandView /> },
      { path: V2Routes.INVESTOR_PORTFOLIO_COMPANY_DETAILS, element: <CompanyDetailsPage /> },
      { path: V2Routes.INVESTOR_PULSE, element: <InvestorPulsePage /> },
      { path: V2Routes.INVESTOR_CONFIRM_INVESTMENT, element: <InvestmentConfirmationPage /> },
    ],
  },
];
