import { FounderOnboardingContextProvider } from '@/context/founder-onboarding/founder-onboarding.provider';
import { InvestorOnboardingContextProvider } from '@/context/investor-onboarding/investor-onboarding.provider';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { Outlet } from 'react-router-dom';

export const OnboardingLayout = () => {
  const { state } = useUserAccount();

  const ContextProvider =
    state.userRole === 'founder' ? FounderOnboardingContextProvider : InvestorOnboardingContextProvider;

  return (
    <ContextProvider>
      <Outlet />
    </ContextProvider>
  );
};
