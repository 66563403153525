import type { V2IconProps } from '../../v2-icon/v2-icon.types';
import { useV2ButtonContext } from '../v2-button.context';

import S from '../v2-button.styles';

type IconProps = V2IconProps;

export const Icon = (props: IconProps) => {
  const { buttonSize, variant } = useV2ButtonContext();
  const sizeLookup: Record<string, V2IconProps['size']> = {
    small: 'sm',
    large: 'sm',
    auto: 'sm',
  };

  return <S.Icon variant={variant} size={sizeLookup[buttonSize ?? 'large']} {...props} />;
};
