import type { UserAccountAction } from '@context/user/user-account.reducer';
import { Roles } from '@domain/accounts/roles';
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
import { OnboardingStatus } from '@domain/accounts/types';
import {
  Auth0ProviderValue,
  type ABTestGroup,
  type ApiDefinedPermission,
  type RoleOnboardingStatus,
} from '@pages/auth/api/account-data/account-data.types';
import { isTestRunning } from '@utils/fns/is-test-running';
import type { CurrencyValue } from '@utils/type-definitions/currencies';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { createContext, type Dispatch } from 'react';

export interface UserAccountContextApi {
  state: UserAccountState;
  dispatch: Dispatch<UserAccountAction>;
  helpers: {
    isFrom: (countryCode: CountryCodes | 'RoW') => boolean;
  };
}

export interface UserAccountState {
  initialized: boolean;
  isAuthorized: boolean;
  isLoggingOut: boolean;
  isLoggingIn: boolean;
  userId: string | null;
  userRole: Roles;
  email: string | null;
  products: SubscriptionProduct[];
  avatarUrl: string | null;
  name: string;
  businessName: string | null;
  currency: CurrencyValue | null;
  countryOfResidence: CountryCodes | null;
  integrations: {
    codat: {
      isConnected: boolean;
      isFirstSyncCompleted: boolean;
    };
    crm: {
      id: string | null;
    };
    stripe: {
      customerId: string | null;
    };
  };
  permissions: ApiDefinedPermission[];
  status: AccountStatus;
  auth0Provider: Auth0ProviderValue | null;
  onboarding: RoleOnboardingStatus;
  group: ABTestGroup | null;
}

export enum AccountStatus {
  ACTIVE = 'active',
  AWAITING_SUBSCRIPTION_ASSIGNED = 'awaiting_subscription_assigned',

  AWAITING_ADMIN_ACCEPTANCE = 'awaiting_admin_acceptance',
  BAN = 'ban',
  INACTIVE = 'inactive',
}

export type UserAccountMetadata = Omit<
  UserAccountState,
  'isAuthorized' | 'mode' | 'isLoggingOut' | 'isLoggingIn' | 'initialized'
>;

export const userAccountInitialState: UserAccountState = {
  initialized: Boolean(isTestRunning()),
  userId: null,
  isAuthorized: false,
  isLoggingOut: false,
  isLoggingIn: false,
  userRole: Roles.NO_ROLE,
  email: null,
  products: [],
  avatarUrl: null,
  businessName: '',
  currency: null,
  countryOfResidence: null,
  name: '',
  integrations: {
    codat: {
      isConnected: false,
      isFirstSyncCompleted: false,
    },
    crm: {
      id: null,
    },
    stripe: {
      customerId: null,
    },
  },
  permissions: [],
  status: AccountStatus.INACTIVE,
  auth0Provider: null,
  onboarding: {
    founder: 'NotApplicable' as OnboardingStatus,
    founderQualifyingQuestions: 'NotApplicable' as OnboardingStatus,
    investor: 'NotApplicable' as OnboardingStatus,
    ned: 'NotApplicable' as OnboardingStatus,
  },
  group: null,
};

export const UserAccountContext = createContext<UserAccountState | UserAccountContextApi>(userAccountInitialState);
