import type { FounderOnboardingPathStep } from '@pages/content/onboarding/founder/paths';
import type { OnboardingStepError } from './founder-onboarding.context';

export enum FounderOnboardingActions {
  ADD_ONBOARDING_STEP_ERROR = 'ADD_ONBOARDING_STEP_ERROR',
  REMOVE_ONBOARDING_STEP_ERROR = 'REMOVE_ONBOARDING_STEP_ERROR',
}

export const addFounderOnboardingStepError = (payload: OnboardingStepError<FounderOnboardingPathStep>) => ({
  type: FounderOnboardingActions.ADD_ONBOARDING_STEP_ERROR as const,
  payload,
});

export const removeFounderOnboardingStepError = (payload: string) => ({
  type: FounderOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR as const,
  payload,
});
