import { useRoleBasedLink } from '@/hooks/use-role-based-link';
import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { V2Links } from '@/v2-router/const/links';
import SmileIcon from '@assets/icons/smile-wink.svg?react';
import { Roles } from '@domain/accounts/roles';
import { CenterBlock } from '@layout/center-block/center-block.layout';
import { PatternLayout } from '@layout/pattern/pattern.layout';
import BigBrandHeading from '@parts/big-brand-heading/big-brand-heading';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { NarrowLink } from './checkout/checkout.page.styles';
import { PaymentInfoContainer } from './payment-info.page.styles';

export const SuccessfulPaymentPage = () => {
  useEnforceTheme('dark');

  const [headingText, descriptionText, descriptionTextFounder, buttonText, buttonTextFounder] = useTranslation([
    'paymentSuccess.heading',
    'paymentSuccess.description',
    'paymentSuccess.descriptionFounder',
    'paymentSuccess.button',
    'paymentSuccess.buttonFounder',
  ]);
  const {
    state: { userRole },
  } = useUserAccount();

  const getDescriptionText = () => {
    const text = userRole === Roles.FOUNDER ? descriptionTextFounder : descriptionText;

    return <p>{text}</p>;
  };

  const linkUrl = useRoleBasedLink({
    [Roles.FOUNDER]: V2Links.founderHomepage(),
    [Roles.NED]: V2Links.advisorProfile(),
    [Roles.INVESTOR]: V2Links.investorProfile(),
    [Roles.INVESTOR_NED]: V2Links.investorAdvisorProfile({ role: 'investor' }),
  });

  const getButton = () => {
    const text = userRole === Roles.FOUNDER ? buttonTextFounder : buttonText;

    return (
      <NarrowLink data-testid="start-using-app-button" to={linkUrl}>
        {text}
      </NarrowLink>
    );
  };

  return (
    <PatternLayout>
      <CenterBlock gradient>
        <PaymentInfoContainer data-testid="payment-info-container-successful">
          <SmileIcon data-testid="payment-smile-icon" />
          <BigBrandHeading>{headingText}</BigBrandHeading>
          {getDescriptionText()}
          {getButton()}
        </PaymentInfoContainer>
      </CenterBlock>
    </PatternLayout>
  );
};
