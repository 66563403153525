import { GET_FOUNDER_DASHBOARDS } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import {
  GET_METRICS_ACTION_CACHE_KEY,
  MetricType,
  getFounderMetricsAction,
} from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { addDashboardElementsAction } from '@pages/content/pulse/parts/dashboards/api/add-metric/add-dashboard-elements.action';
import { useDrawer } from '@parts/drawer/use-drawer';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { FormikStringInput } from '@parts/forms/formik-string-input/formik-string-input';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';
import { createDashboardAction } from '../../../api/create-dashboard/create-dashboard.action';
import { GET_FOUNDER_DASHBOARD_CACHE_KEY } from '../../../api/get-founder-dashboard/get-founder-dashboard.action';
import { dashboardIdParam } from '../../dashboards-menu/dashboards-menu';
import { SearchableMetrics } from '../add-metric/parts/searchable-metrics';
import S from './create-dashboard.styles';
import { useValidationSchema, visibilityValues } from './validation-schema';

const CreateDashboard = ({ hasCloseConnection }: { hasCloseConnection?: boolean }) => {
  const navigate = useNavigate();
  const { [dashboardIdParam]: id } = useQueryParams();

  const { Drawer } = useDrawer(true);
  const [
    titleLabel,
    nameLabel,
    visibilityLabel,
    createLabel,
    cancelLabel,
    visibilityDescLabel,
    visibilityPrivateLabel,
    visibilityConnLabel,
    visibilityCloseConnLabel,
    visibilityCloseConnTooltipLabel,
    savedLabel,
    metricAddedLabel,
    metricsLabel,
  ] = useTranslation([
    'pulse.dashboard.title',
    'pulse.dashboard.name',
    'pulse.dashboard.visibility',
    'global.create',
    'global.cancel',
    'pulse.dashboard.visibility.desc',
    'dashboards.visibility.private',
    'dashboards.visibility.connections',
    'dashboards.visibility.closeConnections',
    'pulse.dashboard.visibility.closeConnections.tooltip',
    'pulse.dashboard.saved',
    'pulse.dashboard.addMetric.success',
    'pulse.dashboard.addMetric.section.title',
  ]);
  const onClose = () => (newDashboardId?: string) => {
    const dashboardId = newDashboardId || id || undefined;
    navigate({ search: stringify({ [dashboardIdParam]: dashboardId }) });
  };

  const queryClient = useQueryClient();
  const { mutateAsync: createDashboard, isLoading: createDashboardLoading } = useMutation(createDashboardAction, {
    onSuccess: async (res: { data: { dashboardId: string } }) => {
      message.success({
        content: savedLabel,
      });
      await queryClient.invalidateQueries([GET_FOUNDER_DASHBOARDS]);

      onClose()(res.data.dashboardId);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: addDashboardElements } = useMutation(addDashboardElementsAction(), {
    onSuccess: async () => {
      queryClient.invalidateQueries([GET_FOUNDER_DASHBOARD_CACHE_KEY]);
      message.success({
        content: metricAddedLabel,
      });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const formik = useFormik<{
    name: string;
    visibility: string;
    metrics: { id: string; type: MetricType }[];
    addTextBox: boolean;
  }>({
    onSubmit: async ({ addTextBox, metrics, ...rest }) => {
      const {
        data: { dashboardId: requestDashboardId },
      }: { data: { dashboardId: string } } = await createDashboard(rest);

      if (metrics.length || addTextBox) addDashboardElements({ addTextBox, metrics, dashboardId: requestDashboardId });
    },
    initialValues: {
      name: '',
      visibility: '',
      metrics: [],
      addTextBox: false,
    },
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });

  const { data: response, isLoading } = useQuery([GET_METRICS_ACTION_CACHE_KEY], getFounderMetricsAction);
  if (isLoading || !response || !response.data) return <FullHeightSpinner />;

  return (
    <Drawer
      title={titleLabel}
      onClose={() => {
        onClose()();
      }}
      footer={
        <OnTopFooter
          confirmLabel={createLabel}
          cancelLabel={cancelLabel}
          onConfirm={() => {
            formik.submitForm();
          }}
          data-testid="add-dashboard"
          onCancel={() => {
            onClose()();
          }}
          reverseButtons
          loading={createDashboardLoading}
        />
      }
    >
      <FormikStringInput
        data-testid="founder-create-dashboard-name-input"
        formik={formik}
        label={nameLabel}
        name="name"
      />
      <S.Line />
      <FormikField
        label={{ for: 'visibility', label: visibilityLabel }}
        {...formik.getFieldMeta('visibility')}
        datatestid="create-visibility"
      >
        <S.VisibilityDesc>{visibilityDescLabel}</S.VisibilityDesc>
        <div data-testid="visibility-levels">
          <S.Radio.Group {...formik.getFieldProps('visibility')}>
            <div data-testid={`visibility-${visibilityValues[0].toLowerCase()}`}>
              <S.Radio value={visibilityValues[0]}>{visibilityPrivateLabel}</S.Radio>
            </div>
            <div data-testid={`visibility-${visibilityValues[1].toLowerCase()}`}>
              <S.Radio value={visibilityValues[1]}>{visibilityConnLabel}</S.Radio>
            </div>
            {hasCloseConnection ? (
              <div data-testid={`visibility-${visibilityValues[2].toLowerCase()}`}>
                <S.Radio value={visibilityValues[2]}> {visibilityCloseConnLabel}</S.Radio>
              </div>
            ) : (
              <div data-testid={`visibility-${visibilityValues[2].toLowerCase()}`}>
                <S.Radio disabled value={visibilityValues[2]}>
                  <S.DisabledVisibilityText>{visibilityCloseConnLabel}</S.DisabledVisibilityText>
                  <Tooltip title={visibilityCloseConnTooltipLabel} />
                </S.Radio>
              </div>
            )}
          </S.Radio.Group>
        </div>
      </FormikField>
      <S.Line />
      <FormikField {...formik.getFieldMeta('metrics')} label={{ for: 'metrics', label: metricsLabel }}>
        <SearchableMetrics fieldName="metrics" setFieldValue={formik.setFieldValue} metrics={response.data} />
      </FormikField>
    </Drawer>
  );
};

export default CreateDashboard;
