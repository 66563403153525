import { Spin } from 'antd';
import S from './full-height-spinner.styles';

export const FullHeightSpinner = ({
  small = false,
  height = undefined,
  $background = false,
}: {
  small?: boolean;
  height?: string;
  $background?: boolean;
}) => (
  <S.Wrapper data-testid="loader" $height={height} small={small} $background={$background}>
    <Spin size="large" />
  </S.Wrapper>
);
