import { Roles } from '@/domain/accounts/roles';
import { NotificationModules, NotificationTypes } from '@/utils/type-definitions/notifications';
import type { AppAction } from 'src/types';

export const SET_NOTIFICATIONS = 'notifications/set-notifications';
export const MARK_AS_READ = 'notifications/mark-as-read';
export const CLEAR_ALL = 'notifications/clear-all';

interface NotificationPayload {
  roleId: string;
  roleTitle: string;
  profileId: string;
  metricName: string;
  role: Roles.NED | Roles.INVESTOR;
  // AdvisorPlacementCheckIn
  companyName: string;
  // FounderPlacementCheckIn
  advisorName: string;
  placementId: string;
}

export interface Notification {
  id: string;
  module: NotificationModules;
  type: NotificationTypes;
  payload: NotificationPayload | null;
  timestamp: string;
  iconUrl: string | null;
  read: boolean;
  senderName: string;
}

export interface NotificationsState {
  currentNotifications: Notification[];
  subscribed: boolean;
}

export type NotificationsActionType =
  | AppAction<typeof SET_NOTIFICATIONS, { notifications: Notification[] }>
  | AppAction<typeof MARK_AS_READ, { notificationId: string }>
  | AppAction<typeof CLEAR_ALL>;

export const notificationsReducer = (
  state: NotificationsState,
  action: NotificationsActionType,
): NotificationsState => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const currentNotifications = [...state.currentNotifications, ...action.notifications].sort(
        (a, b) => Number(new Date(b.timestamp)) - Number(new Date(a.timestamp)),
      );

      return {
        ...state,
        subscribed: true,
        currentNotifications: Array.from(new Set(currentNotifications.map((n) => n.id))).map(
          (id) => currentNotifications.find((n) => n.id === id)!,
        ),
      };
    }

    case MARK_AS_READ:
      return {
        ...state,
        currentNotifications: state.currentNotifications.map((notification) =>
          notification.id === action.notificationId
            ? {
                ...notification,
                read: true,
              }
            : notification,
        ),
      };

    case CLEAR_ALL:
      return {
        ...state,
        currentNotifications: state.currentNotifications.map((notification) => ({
          ...notification,
          read: true,
        })),
      };

    default:
      return state;
  }
};
