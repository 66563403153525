import { V2Links } from '@/v2-router/const/links';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Link } from 'react-router-dom';
import S from './header.styles';

export const Header = () => {
  const [signOutLabel] = useTranslation(['ui.startOver']);

  const { logout } = useLogout();

  return (
    <S.Wrapper>
      <S.Header data-testid="header">
        <Link to={V2Links.sharedStart()}>
          <S.Logo />
        </Link>
        <S.SignOut type="link" onClick={() => logout()}>
          {signOutLabel}
        </S.SignOut>
      </S.Header>
    </S.Wrapper>
  );
};
