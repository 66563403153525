import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import React, { lazy, Suspense } from 'react';
import { CheckoutPageMode } from './types';

const LazyCheckoutPageContent = lazy(() => import('./checkout.page'));
const LazyStripeElementsWrapper = lazy(() => import('./parts/second-step/parts/stripe-elements/stripe-elements'));

type LazyCheckoutPageProps = {
  mode?: CheckoutPageMode;
};

export const LazyCheckoutPage: React.FC<LazyCheckoutPageProps> = (props) => (
  <Suspense fallback={<FullHeightSpinner />}>
    <LazyStripeElementsWrapper>
      <LazyCheckoutPageContent {...props} />
    </LazyStripeElementsWrapper>
  </Suspense>
);
