import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Button as BaseButton } from 'antd';
import S from './buttons.styles';
import { useNavigate } from 'react-router-dom';

type ButtonsProps = Parameters<typeof BaseButton>[0] & { selectedRole?: string; hideBackButton?: boolean };

const Buttons = ({ disabled, selectedRole = '', hideBackButton = false, ...rest }: ButtonsProps) => {
  const [buttonLabel, changeAccountTypeLabel] = useTranslation([
    'register.continue.button',
    'register.changeAccountType.button',
  ]);

  const navigate = useNavigate();

  return (
    <S.ButtonsWrapper>
      {!hideBackButton ? (
        <S.ChangeType type="link" onClick={() => navigate(-1)}>
          {changeAccountTypeLabel}
        </S.ChangeType>
      ) : null}
      <S.Continue disabled={disabled} {...rest}>
        {buttonLabel}
        {selectedRole}
      </S.Continue>
    </S.ButtonsWrapper>
  );
};

export default Buttons;
