import { V2Links } from '@/v2-router/const/links';
import { PlusOutlined } from '@ant-design/icons';
import DropdownIcon from '@assets/icons/dropdown-blue-tick.svg?react';
import type { Dashboard } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import { MobileDashboardsMenu } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/mobile-dashboards-menu';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Tooltip } from 'antd';
import { stringify } from 'query-string';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { newDashboardParam } from '../../founder-dashboards.layout';
import CreateDashboard from '../dashboard/create-dashboard/create-dashboard';
import { DashboardsEmptyState } from './parts/dashboards-empty-state';

import S from './dashboards-menu.styles';

export const dashboardIdParam = 'dashboardId';
export const founderIdParam = 'id';

export const DashboardsMenu = ({
  dashboards,
  showAddDashboardButton,
  showManageDataButton,
  hasCloseConnection,
}: {
  dashboards: Dashboard[] | null | undefined;
  showManageDataButton?: boolean;
  showAddDashboardButton?: boolean;
  hasCloseConnection?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: {
      integrations: {
        codat: { isConnected },
      },
    },
  } = useUserAccount();

  const queryParams = useQueryParams() as {
    [dashboardIdParam]: string;
    [newDashboardParam]: string;
    [founderIdParam]: string;
    [key: string]: string;
  };
  const { [dashboardIdParam]: id, [founderIdParam]: founderId, [newDashboardParam]: isAddingNew } = queryParams;

  const onClickMenu = (activeKey: string) => {
    const parsedLocationSearch = stringify({
      ...queryParams,
      [dashboardIdParam]: activeKey || undefined,
      [founderIdParam]: founderId || undefined,
    });

    navigate(`${location.pathname}?${parsedLocationSearch}`);
  };

  let selectedDashboard: Dashboard | null = null;
  let lastDashboard = dashboards?.slice(-1)[0] ?? null;
  selectedDashboard = dashboards?.find((dashboard) => dashboard.id === id) ?? lastDashboard;

  useEffect(() => {
    lastDashboard = dashboards?.slice(-1)[0] ?? null;
    selectedDashboard = dashboards?.find((dashboard) => dashboard.id === id) ?? lastDashboard;
  }, [id]);

  useEffect(() => {
    if (dashboards?.length) {
      const parsedLocationSearch = stringify({
        ...queryParams,
        [dashboardIdParam]: selectedDashboard?.id ?? lastDashboard?.id ?? dashboards[0]?.id ?? undefined,
        [founderIdParam]: founderId || undefined,
      });

      navigate(`${location.pathname}?${parsedLocationSearch}`);
    }
  }, [selectedDashboard, lastDashboard]);

  const [moreDashboardsLabel, manageDataLabel] = useTranslation([
    'founder.pulseDashboard.moreDashboards',
    'founder.pulseDashboard.manageData',
  ]);

  const handleOpenAddForm = () => {
    const parsedLocationSearch = stringify({
      ...queryParams,
      [dashboardIdParam]: id || undefined,
      [founderIdParam]: founderId || undefined,
      [newDashboardParam]: true,
    });

    navigate(`${location.pathname}?${parsedLocationSearch}`);
  };

  if (!dashboards) return <FullHeightSpinner />;

  const handleManageDataClick = () => {
    const linkToMetrics = V2Links.founderMetrics({ mode: isConnected ? 'integrated-metrics' : 'metrics' });
    const query = selectedDashboard?.id ? { [dashboardIdParam]: selectedDashboard.id } : {};

    navigate({ pathname: linkToMetrics, search: stringify(query) });
  };

  const onEdit = (_e: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
    if (action === 'add') {
      handleOpenAddForm();
    }
  };

  return (
    <>
      <MobileDashboardsMenu
        dashboards={dashboards}
        selectedDashboard={selectedDashboard}
        onClickMenu={onClickMenu}
        onAddClick={handleOpenAddForm}
      />
      <S.Tabs
        tabPosition="top"
        type={`${showAddDashboardButton ? 'editable-' : ''}card`}
        onTabClick={onClickMenu}
        activeKey={selectedDashboard?.id || ''}
        onEdit={onEdit}
        tabBarExtraContent={
          showManageDataButton ? (
            <S.ManageDataBtn onClick={handleManageDataClick} type="link">
              {manageDataLabel}
            </S.ManageDataBtn>
          ) : undefined
        }
        addIcon={
          <span data-testid="founder-pulse-add-dashboard-trigger-button">
            <PlusOutlined />
          </span>
        }
        renderTabBar={(tabBarProps, DefaultTabBar) => (
          <DefaultTabBar
            {...tabBarProps}
            moreIcon={
              <>
                {moreDashboardsLabel}
                <DropdownIcon />
              </>
            }
          />
        )}
      >
        {dashboards.map((dashboard) => (
          <S.Tabs.TabPane
            data-testid="dashboard-menu-item"
            tab={<Tooltip title={dashboard.name}>{dashboard.name}</Tooltip>}
            key={dashboard.id}
            closable={false}
            destroyInactiveTabPane
          />
        ))}
      </S.Tabs>
      {isAddingNew && <CreateDashboard hasCloseConnection={hasCloseConnection} />}
      {dashboards.length === 0 && (
        <DashboardsEmptyState showAddDashboardButton={showAddDashboardButton} displayAddForm={handleOpenAddForm} />
      )}
    </>
  );
};
