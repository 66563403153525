import { V2Links } from '@/v2-router/const/links';
import { resourcesLink } from '@constants/links';
import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
} from '@context/investor-onboarding/investor-onboarding.actions';
import { INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY } from '@pages/content/onboarding/investor/paths';
import { type StepContentProps } from '@pages/content/onboarding/investor/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  GET_INVESTOR_SELF_CERTIFICATION_CATEGORY,
  getInvestorSelfCertificationCategory,
} from '../../api/get-investor-self-certification-category.action';
import { setSelfCertificationCategoryAction } from '../../api/set-investor-self-certification-category.action';
import S from './affirmations.styles';
import { AffirmationsEditor } from './parts/editor/editor';

export const AffirmationsPageContent = ({ currentPage, endPage }: StepContentProps) => {
  const affirmationsInitialDataLoadErrorId = `${INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY}-initial-load-data-error`;
  const [titleLabel, subTitleLabel, infoLinkLabel, accreditedTitle, accreditedSubtitle] = useTranslation([
    'investor-onboarding.affirmations.title',
    'investor-onboarding.affirmations.subTitle',
    'investor-onboarding.affirmations.infoLink',
    'investor-onboarding.affirmations.accredited.title',
    'investor-onboarding.affirmations.accredited.subTitle',
  ]);

  const {
    state: { accountType },
    dispatch: investorOnboardingDispatch,
  } = useInvestorOnboardingContext();

  const {
    helpers: { isFrom },
  } = useUserAccount();

  const isUserUS = isFrom(CountryCodes.US);

  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
  } = useQuery([GET_INVESTOR_SELF_CERTIFICATION_CATEGORY], getInvestorSelfCertificationCategory, {
    onSuccess: () => investorOnboardingDispatch(removeInvestorOnboardingStepError(affirmationsInitialDataLoadErrorId)),
  });

  const { mutateAsync, isLoading: isMutating } = useMutation(setSelfCertificationCategoryAction, {
    onSuccess: () => refetch(),
    onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
  });

  useEffect(() => {
    investorOnboardingDispatch(
      addInvestorOnboardingStepError({
        id: affirmationsInitialDataLoadErrorId,
        step: INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY,
        error: '',
        disableButton: true,
      }),
    );
  }, []);

  const data = response?.data;
  const showSpinner = isLoading || !data;

  const disableFields = isMutating || isFetching;

  if (!accountType) {
    return <Navigate to={V2Links.onboardingInvestor()} />;
  }

  const titleSection = isUserUS ? (
    <Title
      title={accreditedTitle}
      subHeadline={<>{accreditedSubtitle}</>}
      currentPage={currentPage}
      endPage={endPage}
    />
  ) : (
    <Title
      title={titleLabel}
      subHeadline={
        <>
          {subTitleLabel}
          <br />
          <S.Link href={resourcesLink} outer target="_blank">
            {infoLinkLabel}
          </S.Link>
        </>
      }
      currentPage={currentPage}
      endPage={endPage}
    />
  );

  return (
    <>
      {titleSection}

      {showSpinner ? (
        <FullHeightSpinner small />
      ) : (
        <AffirmationsEditor
          data={data}
          investorType={accountType!}
          disabled={disableFields}
          onChange={mutateAsync}
          isUserUS={isUserUS}
        />
      )}
    </>
  );
};
