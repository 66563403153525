import { Roles } from '@/domain/accounts/roles';
import { getRoleBasedLink } from '@/hooks/use-role-based-link';
import { V2Links } from '@/v2-router/const/links';
import { finishLogin, startLogin } from '@context/user/user-account.actions';
import { getRole } from '@domain/accounts/get-role';
import { createProductFromValue } from '@domain/accounts/subscription-products';
import { LOGIN_AS_ADMIN_STORAGE_KEY } from '@domain/admin/admin.types';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useQuery } from '@tanstack/react-query';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { tokenStorage } from '@utils/token/token-storage';
import jwt from 'jsonwebtoken';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_DATA_CACHE_KEY, getAccountData } from '../api/account-data/account-data.actions';

const MAX_RETRIES = 1;

export const LoginAsUserPage = () => {
  const [successMessage, errorMessage] = useTranslation(['admin.loginAsUser.success', 'admin.loginAsUser.error']);

  const navigate = useNavigate();
  const { token } = useQueryParams() as { token: string };

  const { dispatch } = useUserAccount();

  const [retriesCount, setRetriesCount] = useState(0);
  const { refetch: fetchAccountData } = useQuery([ACCOUNT_DATA_CACHE_KEY], getAccountData, {
    enabled: false,
    onSuccess: (res) => {
      if (!res?.data) {
        if (retriesCount < MAX_RETRIES) {
          setRetriesCount((prevVal) => prevVal + 1);
          fetchAccountData();
          return;
        }

        message.error({ content: errorMessage, duration: 0 });
        return;
      }

      const products = res?.data?.products.map(createProductFromValue);
      const userRole = getRole(products, res.data.role);

      const { userId } = jwt.decode(token) as { userId: string };

      dispatch(finishLogin(userId, userRole, products));
      const profileUrl = getRoleBasedLink(
        {
          [Roles.FOUNDER]: V2Links.founderProfile(),
          [Roles.NED]: V2Links.advisorProfile(),
          [Roles.INVESTOR]: V2Links.investorProfile(),
          [Roles.INVESTOR_NED]: V2Links.investorAdvisorProfile({ role: 'investor' }),
        },
        userRole,
      );
      navigate(profileUrl);

      localStorage.setItem(LOGIN_AS_ADMIN_STORAGE_KEY, 'true');

      message.success({ content: successMessage });
    },
  });

  useEffect(() => {
    if (!token) {
      navigate(V2Links.sharedStart());
      return;
    }

    const login = async () => {
      const { exp } = jwt.decode(token) as { exp: number };

      tokenStorage.setAccessToken(token, exp);

      dispatch(startLogin());
      fetchAccountData();
    };

    login();
  }, [token]);

  return <FullHeightSpinner />;
};
