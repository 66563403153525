import { Roles } from '@/domain/accounts/roles';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { Navigate, useLocation } from 'react-router-dom';
import { V2Links } from '../const/links';

export const HandleStartRoute = () => {
  const location = useLocation();
  const { state } = useUserAccount();

  const roleToRoute: Record<Roles, string> = {
    [Roles.FOUNDER]: V2Links.founderHomepage(),
    [Roles.INVESTOR]: V2Links.investorHomepage(),
    [Roles.NED]: V2Links.advisorHomepage(),
    [Roles.INVESTOR_NED]: V2Links.investorAdvisorHomepage(),
    [Roles.NO_ROLE]: V2Links.sharedLogin(),
  };

  return <Navigate to={{ pathname: roleToRoute[state.userRole], search: location.search }} replace />;
};
