import { TranslationText } from '@/parts/translation-text/translation-text';
import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { V2Links } from '@/v2-router/const/links';
import { LegacyRoutes } from '@/v2-router/const/routes';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Alert } from 'antd';
import { stringify } from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { finishInterruptedRegistrationAction } from '../../../api/register/finish-interrupted-registration.action';
import {
  INVITATION_TOKEN_KEY,
  type InvitationTokenData,
} from '../../invitation-registration/invitation-registration.page';
import { useAuth0RegisterToken } from '../hooks/use-auth0-token/use-auth0-token';
import RegisterWrapper from '../parts/register-wrapper/register-wrapper';
import { CONTINUE_AUTH0_URL } from '../register-form/founder-form/founder-form';
import { REFERRAL_CODE_KEY, type ReferralCode } from '../register-form/register-form.page';
import { ChooseRoleForm } from './form/choose-role.form';
import { ProviderInfo } from './provider-info/provider-info';

import S from './choose-role.page.styles';

export const ChooseRolePage = () => {
  useEnforceTheme('dark');
  const { isValid: isTokenValid, payload: tokenPayload, token } = useAuth0RegisterToken();
  const { state: queryParamsState } = useQueryParams<{ state?: string }>();
  const storageInvitationToken = localStorage.getItem(INVITATION_TOKEN_KEY);
  const storageReferralCode = localStorage.getItem(REFERRAL_CODE_KEY);
  const navigate = useNavigate();
  const { logout } = useLogout();

  const [defaultRequestErrorTitle, defaultRequestErrorMessage, logoutLabel] = useTranslation([
    'messages.error.defaultTitle',
    'messages.error.default',
    'messages.error.logout',
  ]);

  const [errorText, setErrorText] = useState<string | null>(null);
  const [showLogout, setShowLogout] = useState<boolean>(false);

  const {
    mutate: checkFinishInterruptedRegistration,
    isLoading,
    isSuccess: wasInterrupted,
  } = useMutation(finishInterruptedRegistrationAction, {
    onError(error: AxiosError) {
      // If user account is not found in the DB continue normal register flow
      // in case of any other error show error message
      const errorName = error.response?.data.name;
      const interruptedRegistrationErrorName = 'NotFoundError';
      const interruptedRegistrationInvariantErrorName = 'InvariantError';

      if (errorName !== interruptedRegistrationErrorName) {
        const errorMessage = error.response?.data.error;
        setErrorText(errorMessage ?? defaultRequestErrorMessage);
      }
      if (errorName === interruptedRegistrationInvariantErrorName) {
        setShowLogout(true);
      }
    },
    onSuccess(response) {
      window.location.href = `${CONTINUE_AUTH0_URL}?${stringify({
        token: response.data.token,
        state: queryParamsState!,
      })}`;
    },
  });

  useEffect(() => {
    if (!token || !queryParamsState) return;
    checkFinishInterruptedRegistration({ registrationToken: token as string, state: queryParamsState });
  }, []);

  useEffect(() => {
    if (storageInvitationToken && isTokenValid) {
      const invitationToken: InvitationTokenData = JSON.parse(storageInvitationToken);
      navigate({
        pathname: V2Links.sharedRegisterRole({ role: invitationToken.role || '' }),
        search: window.location.search,
      });
    } else if (storageReferralCode && isTokenValid) {
      const referralCode: ReferralCode = JSON.parse(storageReferralCode);
      navigate({
        pathname: V2Links.sharedRegisterRole({ role: referralCode.role || '' }),
        search: window.location.search,
      });
    } else if (isTokenValid === false) {
      navigate(LegacyRoutes.ROOT, { replace: true });
    }
  }, [storageInvitationToken, storageReferralCode, isTokenValid]);

  const errorElement = (
    <Alert
      message={defaultRequestErrorTitle}
      description={
        <>
          {errorText}
          {showLogout ? <S.LogoutButton onClick={() => logout()}>{logoutLabel}</S.LogoutButton> : null}
        </>
      }
      type="error"
      showIcon
    />
  );

  const providerInfo = (() => {
    if (tokenPayload?.provider === 'xero-oauth2') {
      return (
        <ProviderInfo>
          <TranslationText id="register.provider.xero.info" />
        </ProviderInfo>
      );
    }
    return null;
  })();

  return storageInvitationToken || storageReferralCode || isTokenValid === false || isLoading || wasInterrupted ? (
    <FullHeightSpinner />
  ) : (
    <>
      <RegisterWrapper>
        {providerInfo}
        {errorText ? errorElement : <ChooseRoleForm registerProvider={tokenPayload!.provider} />}
      </RegisterWrapper>
    </>
  );
};
