import { Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Any } from 'src/types';

export const AppAuth0Provider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: Any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const auth0Config: Auth0ProviderOptions = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    },
    onRedirectCallback,
  };

  return <Auth0Provider {...auth0Config}>{children}</Auth0Provider>;
};
