import { axiosInstance } from '@utils/axios/axios-instance';

export enum CodatRequestContextValue {
  ReportMetrics = 'ReportMetrics',
  ReportIntegrations = 'ReportIntegrations',
  Onboarding = 'Onboarding',
}

export const connectCodatAction = async (
  requestContext: CodatRequestContextValue,
): Promise<{ data: { redirectUrl: string } }> => axiosInstance.post('/integration/codat/connect', { requestContext });
