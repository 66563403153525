import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { PreviousInvestmentWithId } from '../../../../investment';
import { Columns } from './columns';

import S from './table.styles';

export const PreviousInvestmentsTable = ({
  data,
  onEditRow,
  onRemoveRow,
  disabled = false,
  hideActionsColumn = false,
}: {
  data: PreviousInvestmentWithId[];
  onEditRow?: (row: PreviousInvestmentWithId) => void;
  onRemoveRow?: (row: PreviousInvestmentWithId) => void;
  disabled?: boolean;
  hideActionsColumn?: boolean;
}) => {
  const [companyNameLabel, notesLabel] = useTranslation([
    'profile.investor.section.investments.table.companyName',
    'profile.investor.section.investments.table.notes',
  ]);

  return (
    <S.Table
      dataSource={data}
      rowKey="id"
      columns={Columns({
        onEditRow,
        onRemoveRow,
        translations: {
          companyName: companyNameLabel,
          notes: notesLabel,
        },
        disableActions: disabled,
        hideActionsColumn,
      })}
    />
  );
};
