import { SecureLink } from '@parts/link/link';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import { V2Links } from '@/v2-router/const/links';
import S from './nav.styles';

export const Nav = ({ finishLater, onFinishLater }: { finishLater?: boolean; onFinishLater?: () => void }) => {
  const { logout } = useLogout();
  const [signOutLabel, finishLaterLabel] = useTranslation([
    'navigation.wizard.signOut',
    'navigation.wizard.finishLater',
  ]);

  return (
    <S.Nav data-testid="wizard-layout-nav">
      <SecureLink href={V2Links.sharedStart()} type="link">
        <S.Logo />
      </SecureLink>

      <S.SignOut type="link" onClick={finishLater ? onFinishLater : logout}>
        {finishLater ? finishLaterLabel : signOutLabel}
      </S.SignOut>
    </S.Nav>
  );
};
