import { V2Links } from '@/v2-router/const/links';
import { isActiveInvestor } from '@abilities';
import { InvestorSummaryContent } from '@pages/content/onboarding/investor/content/summary/summary-page';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Navigate } from 'react-router-dom';

export const InvestorOnboardingSummaryPage = () => {
  const {
    state: { products },
  } = useUserAccount();

  if (isActiveInvestor(products)) {
    return <Navigate to={V2Links.sharedStart()} />;
  }

  return (
    <OnboardingLayout showFooter={false} onNextClick={null} centerContent>
      <InvestorSummaryContent />
    </OnboardingLayout>
  );
};
