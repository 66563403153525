import { V2Links } from '@/v2-router/const/links';
import { LegacyRoutes } from '@/v2-router/const/routes';
import { storeErrorNoticeInLocalStorage } from '@utils/honeybadger/utils/local-storage-error-reporter';
import { createGraphQlErrorNotice } from '@utils/honeybadger/utils/notifications';
import type { Any, GraphQlErrorResponse, GraphqlResponse } from 'src/types';

const INTERNAL_SERVER_ERROR_NAME = 'INTERNAL_SERVER_ERROR';
const debugEnvironments = ['local', 'development', 'feature-preview', 'staging'];

type Rename = [string, string];

export const isDebugMode = (): boolean => window.localStorage.getItem('debug') === 'true';

const handleErrorRedirection = (response: GraphQlErrorResponse): void => {
  const messagesList = [
    'Unauthorized Error has occurred.',
    'Access denied! You need to be authorized to perform this action!',
    'Founder and Ned accounts must be connected or Both founder and Ned accounts must be published or Advisor must apply for your role before conversation.',
  ];

  const isInternalServerErrorPage = window.location.href.includes(LegacyRoutes.INTERNAL_SERVER_ERROR);
  const isEnvironmentForDebug = debugEnvironments.includes(process.env.REACT_APP_ENV);
  if (isInternalServerErrorPage || (isDebugMode() && isEnvironmentForDebug)) {
    return;
  }

  if (response.errors[0].message === 'Ned account must be active to preview public data.') {
    return;
  }

  const hasGraphQlInternalServerError = response?.errors?.[0]?.extensions?.code === INTERNAL_SERVER_ERROR_NAME;
  if (hasGraphQlInternalServerError && !messagesList.includes(response.errors[0].message)) {
    const notice = createGraphQlErrorNotice(response);
    storeErrorNoticeInLocalStorage(notice);

    window.location.href = V2Links.error500();
  }
};

export const graphqlResponseWrapper = <T = Any>(response: Any, keys: (Rename | string)[]): GraphqlResponse<T> => {
  const isShallow = keys.length === 1;

  if (response.data && !response.errors) {
    if (isShallow) {
      return { data: response.data[Array.isArray(keys[0]) ? keys[0][0] : keys[0]], errors: null };
    }

    const data: { [key: string]: Any } = {};

    for (const key of keys) {
      if (Array.isArray(key)) {
        data[key[1]] = response.data[key[0]];
      } else {
        data[key] = response.data[key];
      }
    }

    return { data: data as T };
  }

  handleErrorRedirection(response);

  const errorResult = { ...response, data: null };
  return errorResult;
};
