import message from '@parts/message/message';
import { useModal } from '@parts/modal/use-modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import qs from 'qs';
import queryString from 'query-string';
import { deleteTextboxAction } from '../../../api/delete-textbox/delete-textbox.action';
import { textBoxIdParam, updateTextBoxParam } from '../../../founder-dashboards.layout';
import DES from '../dashboard-element/dashboard-element.styles';
import ChangeSize, { Size } from '../metrics/parts/metric-item/parts/change-size/change-size';
import { DragIcon } from '../metrics/parts/metric-item/parts/drag-icon/drag-icon';
import UpdateTextBox from '../update-text-box/update-text-box';
import { DeleteTextbox } from './parts/delete-textbox';
import { TextEllipsis } from './parts/text-ellipsis/text-ellipsis';
import S from './textbox-element.styles';
import { useNavigate } from 'react-router-dom';

type TextBoxElementProps = {
  id: string;
  title: string | null;
  mainText: string | null;
  isEditingMode: boolean;
  dashboardId: string;
  sizeKey?: string;
  onSizeChange?: (size: Size, itemId: string, preventSmall?: boolean, mediumHeight?: boolean) => void;
  dashboardDetailsQueryToInvalidate?: string[];
};

export const TextBoxElement = ({
  id,
  title,
  mainText,
  isEditingMode,
  onSizeChange,
  dashboardId,
  sizeKey,
  dashboardDetailsQueryToInvalidate = [],
}: TextBoxElementProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { [updateTextBoxParam]: isUpdateTextBox, [textBoxIdParam]: textBoxId } = useQueryParams() as {
    [updateTextBoxParam]: string;
    [textBoxIdParam]: string;
  };

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const [emptyTitleText, emptyContentPlaceholder, readMoreClose, ellipsisText, successfullyDeletedTextbox] =
    useTranslation([
      'pulse.dashboard.textBox.empty.title',
      'pulse.dashboard.textBox.empty.content',
      'pulse.dashboard.textBox.readMore.close',
      'pulse.dashboard.textBox.readMore.text',
      'pulse.dashboard.textBox.delete.success',
    ]);

  const currentSelectedTextBox = id === textBoxId;
  const emptyContentText = isEditingMode ? emptyContentPlaceholder : '';

  const { mutateAsync: deleteTextbox } = useMutation(deleteTextboxAction, {
    onSuccess: async () => {
      message.success({
        content: successfullyDeletedTextbox,
      });
      await queryClient.invalidateQueries(dashboardDetailsQueryToInvalidate);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const onEditClick = () => {
    const parsedLocationSearch = {
      ...queryString.parse(window.location.search),
      [updateTextBoxParam]: true,
      textBoxId: id,
    };

    navigate(`?${qs.stringify(parsedLocationSearch)}`);
  };

  const { Modal: ReadMoreModal, open, hide, show } = useModal();

  const handleSizeChange = (size: Size, preventSmall?: boolean) => {
    if (onSizeChange && id) onSizeChange(size, id, preventSmall, true);
  };

  const HeaderNav = (
    <DES.MetricIcons hoverEffectDisabled={!isEditingMode}>
      {isEditingMode && isDesktop && <ChangeSize onClick={handleSizeChange} disableSmall={false} />}
      {isEditingMode && (
        <>
          <DES.Button
            data-testid={`${(title ?? 'text box').toLowerCase().replace(/\s/g, '-')}-edit-pen`}
            type="text"
            onClick={onEditClick}
          >
            <DES.EditIcon />
          </DES.Button>
          {isEditingMode && <DeleteTextbox onRemove={() => deleteTextbox({ id, dashboardId })} />}
          {isEditingMode && isUpdateTextBox && currentSelectedTextBox && (
            <UpdateTextBox dashboardId={dashboardId} textBoxId={id} title={title} mainText={mainText} />
          )}
        </>
      )}
    </DES.MetricIcons>
  );

  return (
    <DES.Wrapper>
      <DES.Header>
        {isEditingMode && isDesktop && <DragIcon />}
        <Tooltip title={title}>
          <DES.Title>{title ?? emptyTitleText}</DES.Title>
        </Tooltip>
        {HeaderNav}
      </DES.Header>
      <S.Content isEmpty={mainText === null} onClick={() => mainText && show()}>
        <TextEllipsis
          lines={10}
          recalcDelay={300}
          text={mainText ?? emptyContentText}
          recalcDeps={[mainText ?? '', sizeKey ?? '']}
          ellipsisText={ellipsisText}
        />
      </S.Content>
      <ReadMoreModal
        visible={open}
        closeModal={hide}
        title={title ?? emptyTitleText}
        onCancel={hide}
        testId="read-more-modal"
        footer={
          <S.ModalFooter>
            <S.ModalButton onClick={hide}>{readMoreClose}</S.ModalButton>
          </S.ModalFooter>
        }
      >
        <S.ModalContent>{mainText}</S.ModalContent>
      </ReadMoreModal>
    </DES.Wrapper>
  );
};
