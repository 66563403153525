import { translations } from '@utils/i18n/messages';
import { useMemo, useState, type ReactNode, useContext, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { AppLocale, DEFAULT_LOCALE, LocaleContext } from './locale.context';
import { CountryCodes } from '@/utils/type-definitions/iso-to-country-name';

interface LocaleProviderProps {
  children: ReactNode;
}

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const [locale, setLocale] = useState<AppLocale>('US' === CountryCodes.US ? AppLocale.enUS : AppLocale.en);

  const contextValue = useMemo(
    () => ({
      defaultLocale: DEFAULT_LOCALE,
      locale,
      setLocale,
    }),
    [locale, setLocale],
  );

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>
    </IntlProvider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  const { locale, setLocale } = context;

  const updateLocale = useCallback(
    (newLocale: AppLocale) => {
      setLocale(newLocale);
    },
    [setLocale],
  );

  return {
    locale,
    updateLocale,
  };
};
