/* eslint-disable  @typescript-eslint/no-explicit-any */

import type { AxiosError, AxiosResponse } from '@/utils/axios/types';
import { isTurnstileInterceptorSuccessResponseData } from '@/utils/axios/utils/interceptors';
import HiddenFileInput from '@pages/content/parts/hidden-file-input/hidden-file-input';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Progress } from 'antd';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ReactNode,
  type Ref,
  type RefObject,
} from 'react';
import Timeout from 'smart-timeout';
import type { AllOrNone } from 'src/types';
import FileName from '../../pages/content/profile/parts/file-name/file-name';
import UploadedFile from '../../pages/content/profile/parts/uploaded-file/uploaded-file';
import message from '../message/message';
import S from './file-upload.styles';

enum UploadStages {
  EMPTY_STATE = 'emptyState',
  UPLOADING = 'uploading',
  DONE = 'done',
}

interface InitialProps {
  uploadMutation: MutationFunction<AxiosResponse<any | undefined>, FormData>;
  deleteMutation?: MutationFunction<AxiosResponse<any>>;
  deleteFile?: () => void;
  onUploadSuccess?: (data: AxiosResponse<any>, variables?: FormData) => void | Promise<unknown>;
  onUploadError?: (error: AxiosError) => void | Promise<unknown>;
  onDeleteSuccess?: (data: AxiosResponse<any>, variables?: FormData) => void | Promise<unknown>;
  onDeleteError?: (error: AxiosError) => void | Promise<unknown>;
  file?: string;
  mimeTypes?: string;
  multipleControlled?: boolean;
  className?: string;
  hidden?: boolean;
  label?: string;
  fileUrl?: string;
}
interface AdditionalProps {
  hiddenMode?: boolean;
  render?: (component: ReactNode, details?: { name: string; isLoading: boolean }) => ReactNode;
}

type FileUploaderProps = InitialProps & AllOrNone<AdditionalProps>;

export interface FileUploadInputRef {
  inputRef: RefObject<HTMLInputElement>;
}

const FileUploader = (
  {
    uploadMutation,
    deleteMutation,
    deleteFile,
    onUploadSuccess,
    onUploadError,
    onDeleteSuccess,
    onDeleteError,
    mimeTypes,
    file,
    multipleControlled,
    className,
    hidden,
    label,
    fileUrl,
    hiddenMode,
    render,
  }: FileUploaderProps,
  ref: Ref<FileUploadInputRef>,
) => {
  const invisibleInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      inputRef: invisibleInputRef,
    }),
    [],
  );

  const [defaultError, uploadFileLabel] = useTranslation(['messages.error.uploadFile', 'fileupload.uploadFile']);

  const [percent, setPercent] = useState<number>(0);
  const [stage, setStage] = useState<UploadStages>(UploadStages.EMPTY_STATE);
  const [fileName, setFileName] = useState<string>(file || '');

  const resetUpload = () => {
    setStage(UploadStages.EMPTY_STATE);
    setFileName('');
  };

  const { mutateAsync: uploadFile } = useMutation(uploadMutation, {
    onSuccess: (res, formData) => {
      if (onUploadSuccess) onUploadSuccess(res, formData);
      if (multipleControlled) {
        resetUpload();
      } else {
        setStage(UploadStages.DONE);
      }
    },
    onError: (err: AxiosError) => {
      if (onUploadError) onUploadError(err);
      if (multipleControlled) {
        resetUpload();
      }
      message.error({ content: err.response?.data.error || defaultError, duration: 6 });
      resetUpload();
    },
    retry: (count, error) => {
      // Retry only if the error is due to turnstile challenge and the count is less than the max retries
      const MAX_RETRIES = 2;
      if (isTurnstileInterceptorSuccessResponseData(error) && count < MAX_RETRIES) return true;
      return false;
    },
  });

  const { mutateAsync: deleteFileMutation } = useMutation(deleteMutation!, {
    onSuccess: (res) => {
      if (onDeleteSuccess) onDeleteSuccess(res);
      resetUpload();
    },
    onError: (err: AxiosError) => {
      if (onDeleteError) onDeleteError(err);
      message.error({ content: err.response?.data.error, duration: 6 });
      setStage(UploadStages.DONE);
    },
  });

  const handleDelete = () => {
    setStage(UploadStages.EMPTY_STATE);

    if (deleteMutation) {
      deleteFileMutation('delete-file');
    }

    if (deleteFile) {
      deleteFile();
    }

    setFileName('');
  };

  const onFileSubmit = async (formData: FormData) => {
    setPercent(0);
    setStage(UploadStages.UPLOADING);
    const selectedFile = invisibleInputRef?.current?.files;

    if (!selectedFile?.length) {
      setStage(UploadStages.EMPTY_STATE);
      return;
    }

    const currentFileName = selectedFile[0].name || 'Resume.pdf';
    setFileName(currentFileName);
    formData.append('fileName', currentFileName);
    uploadFile(formData);
  };

  useEffect(() => {
    const fakeUploadBreakpoints = [20, 45, 70, 95];

    if (stage === UploadStages.UPLOADING) {
      fakeUploadBreakpoints.map((item) => Timeout.set(`percent-${item}`, () => setPercent(item), item * 70));
      return;
    }

    if (stage === UploadStages.DONE || stage === UploadStages.EMPTY_STATE) {
      fakeUploadBreakpoints.map((item) => Timeout.clear(`percent-${item}`));
    }
  }, [stage]);

  const loading = (
    <S.Uploading data-testid="file-uploader-uploading" hiddenMode={Boolean(hiddenMode)} slim>
      <Progress percent={percent} />
      <FileName name={fileName} />
    </S.Uploading>
  );

  const emptyState = (
    <S.EmptyState
      onClick={() => invisibleInputRef.current?.click()}
      data-testid="file-upload-empty"
      $hiddenMode={Boolean(hiddenMode)}
    >
      <HiddenFileInput submitFn={onFileSubmit} ref={invisibleInputRef} accept={mimeTypes} />
      <S.UploadFile /> <S.EmptyStateLabel>{label ?? uploadFileLabel}</S.EmptyStateLabel>
    </S.EmptyState>
  );

  const hasFileUploaded = stage === UploadStages.DONE || fileName;
  const isEmpty = stage === UploadStages.EMPTY_STATE && !fileName;

  const renderUpload = () => {
    if (stage === UploadStages.UPLOADING) return null;
    if (hasFileUploaded && !hiddenMode) {
      return <UploadedFile handleDelete={handleDelete} fileName={fileName} fileUrl={fileUrl} />;
    }
    if (isEmpty) return emptyState;
  };

  const externalLoading = (
    <S.FileUpload className={className} $hiddenMode={false} slim>
      {loading}
    </S.FileUpload>
  );

  const shouldDisplayExternalLoader = hiddenMode && stage === UploadStages.UPLOADING;

  return !hidden ? (
    <>
      <S.FileUpload className={className} $hiddenMode={Boolean(hiddenMode)}>
        {stage === UploadStages.UPLOADING && loading}
        {renderUpload()}
      </S.FileUpload>
      {render?.(externalLoading, { name: fileName, isLoading: Boolean(shouldDisplayExternalLoader) })}
    </>
  ) : null;
};

export const FileUpload = forwardRef(FileUploader);
