import { V2Links } from '@/v2-router/const/links';
import { WelcomePageContent } from '@pages/content/onboarding/investor/content/welcome/welcome-page';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { Navigate, useNavigate } from 'react-router-dom';
import { INVESTOR_ONBOARDING_STEP_TYPE_KEY } from '../paths';

export const InvestorOnboardingWelcomePage = () => {
  const navigate = useNavigate();
  const { state: onboardingState } = useInvestorOnboardingContext();

  const firstStepUrl = V2Links.onboardingInvestorStep({ step: INVESTOR_ONBOARDING_STEP_TYPE_KEY });
  const goToNextStep = async () => {
    navigate(firstStepUrl);
  };

  if (onboardingState.started) {
    return <Navigate to={firstStepUrl} />;
  }

  return (
    <OnboardingLayout onNextClick={goToNextStep} lastPage={false} startingPage centerContent showFooter={false}>
      <WelcomePageContent />
    </OnboardingLayout>
  );
};
