import styled from 'styled-components';
import type { V2IconSize } from './v2-icon.types';

const sizes = {
  xxs: '8px',
  xs: '12px',
  sm: '16px',
  md: '20px',
  lg: '24px',
  xl: '32px',
};

const Wrapper = styled.div<{ $color: string; $size: V2IconSize }>`
  color: ${(props) => props.$color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (typeof props.$size === 'number' ? `${props.$size}px` : sizes[props.$size] ?? sizes.md)};
  height: ${(props) => (typeof props.$size === 'number' ? `${props.$size}px` : sizes[props.$size] ?? sizes.md)};

  > svg {
    width: inherit;
    height: inherit;
  }
`;

export default { Wrapper };
