import type { AnchorHTMLAttributes, DetailedHTMLProps, RefObject } from 'react';
import S from './link.styles';

type SecureLinkBaseProps = {
  href: string;
};
type SecureOuterLink = SecureLinkBaseProps &
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
    outer?: boolean;
  };

export type SecureLinkProps = SecureOuterLink;

export interface LinkOverloaded {
  (props: SecureOuterLink): JSX.Element;
}

export const SecureLink: LinkOverloaded = ({
  rel = 'noreferrer noopener',
  outer,
  href,
  children,
  ...rest
}: SecureLinkProps) =>
  outer ? (
    <a {...rest} rel={rel} href={href}>
      {children}
    </a>
  ) : (
    <S.Link {...rest} to={href} ref={rest.ref as RefObject<HTMLAnchorElement>}>
      {children}
    </S.Link>
  );
