import { V2Routes } from '@/v2-router/const/routes';
import {
  MetricType,
  type ExternalMetric,
  type InternalMetric,
} from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { metricIdQueryParam } from '@pages/content/pulse/constants';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BooleanEditor } from './parts/boolean-editor/boolean-editor';
import { Configuration } from './parts/configuration/configuration';
import { EmptyState } from './parts/empty-state/empty-state';
import { NumericEditor } from './parts/numeric-editor/numeric-editor';

import S from './metrics.styles';

export const Metrics = ({
  integratedMetricsView = false,
  metrics,
}: {
  integratedMetricsView?: boolean;
  metrics: ExternalMetric[] | InternalMetric[];
}) => {
  const { [metricIdQueryParam]: id } = useQueryParams() as { [metricIdQueryParam]: string };

  useEffect(() => {
    scrollToTop();
  }, []);

  const [emptyStateLabel, emptyStateReadOnlyLabel] = useTranslation([
    'metrics.manage.emptyState',
    'metrics.manage.emptyState.readOnly',
  ]);

  const navigate = useNavigate();

  const handleConfigurationFinish = (data: ExternalMetric[] | InternalMetric[]) => {
    const activeMetric = data.find((metric) => metric.id === id);

    if (!activeMetric) {
      navigate({ pathname: V2Routes.FOUNDER_METRICS });
    }
  };

  const selectedMetric = metrics.find((metric) => metric.id === id);

  const editorProps = {
    metricId: id,
    title: selectedMetric?.name ?? '',
  };

  const editor = (
    <S.Editor>
      {selectedMetric?.type !== MetricType.Boolean ? (
        <NumericEditor
          type={selectedMetric?.type || MetricType.Numeric}
          {...editorProps}
          integratedMetricsView={integratedMetricsView}
        />
      ) : (
        <BooleanEditor {...editorProps} readOnly={integratedMetricsView} />
      )}
    </S.Editor>
  );

  return (
    <S.Section>
      <Row>
        <S.Col lg={8} sm={24} xs={24}>
          <Configuration
            data={metrics || []}
            onConfigurationFinish={handleConfigurationFinish}
            integratedMetricsView={integratedMetricsView}
          />
        </S.Col>
        <Col lg={16} sm={24} xs={24}>
          {selectedMetric ? (
            editor
          ) : (
            <EmptyState text={integratedMetricsView ? emptyStateReadOnlyLabel : emptyStateLabel} />
          )}
        </Col>
      </Row>
    </S.Section>
  );
};
