import { createContext, useContext } from 'react';
import type { V2ButtonProps } from './v2-button.types';

const V2ButtonContext = createContext<Pick<V2ButtonProps, 'variant' | 'buttonSize'> | null>(null);

export const useV2ButtonContext = () => {
  const context = useContext(V2ButtonContext);
  if (!context) throw new Error('V2Button.* component must be rendered as child of V2Button component');

  return context;
};

export { V2ButtonContext };
