export const gradients = {
  'pink-purple': 'linear-gradient(270deg, #AF1077 10.78%, #6C2B7B 97.42%)',
  'blue-red-gradient': 'linear-gradient(90deg, #8EAEF6 -22.86%, #E64D65 112.86%)',
  'magenta-gradient': 'linear-gradient(90deg, #DED9E0 0%, #E6007E 112.86%)',
  'green-gradient': 'linear-gradient(90deg, #8EAEF8 -22.86%, #93E396 112.86%)',
  'blue-gradient': 'linear-gradient(90deg, #3E60EB -22.86%, #8AACFF 112.86%)',
  'light-blue-gradient': 'linear-gradient(90deg, #D9E3FE 0%, #EAEDF1 100%)',
  'orange-gradient': 'linear-gradient(135deg, #FF6900 0%, #CF2E2E 100%)',
  'navy-blue-gradient':
    'linear-gradient(92deg, #161F7F 1.16%, #111A72 10.7%, #0D1465 22.74%, #000641 42.69%, #000641 68.16%, #000641 100%)',
} as const;

export type V2GradientsConfig = typeof gradients;

export type V2Gradients = keyof V2GradientsConfig;
