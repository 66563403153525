// @casl/ability imports
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { AbilityAction, AbilitySubject, type AppAbility, type RoleAbilityBuilderFunction } from './types';
// Domain imports
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
// Page imports
import type { ApiDefinedPermission, RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';
// Router imports
// Utility imports
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
// Ability imports
import { createFounderAbility } from './abilities/founder';
import { createInvestorAbility } from './abilities/investor';
import { createInvestorNedAbility } from './abilities/investor-ned';
import { createNedAbility } from './abilities/ned';
import { createNoRoleAbility } from './abilities/no-role';
// Ability utility imports
import { AccountStatus } from '@context/user/user-account.context';
import { Roles } from '@domain/accounts/roles';
import { hasCoRSet } from './abilities/utils/has-cor-set';
import { hasPermissionsToAdvisorCommunity } from './abilities/utils/has-permission-to-advisor-community';
import { hasPermissionsToNedAcademy } from './abilities/utils/has-permission-to-ned-academy';
import { hasPermissionsToLegalDocs } from './abilities/utils/has-permissions-to-legal-docs';
import { hasPermissionsToPortfolio } from './abilities/utils/has-permissions-to-portfolio';

export class RoleAbility {
  private builder: AbilityBuilder<AppAbility> = new AbilityBuilder<AppAbility>(createMongoAbility);

  constructor(
    private readonly isAuthorized: boolean,
    private readonly accountStatus: AccountStatus,
    private role: Roles,
    private readonly products: SubscriptionProduct[],
    private readonly cor: CountryCodes | null,
    private readonly onboarding: RoleOnboardingStatus,
    private readonly apiDefinedPermissions: ApiDefinedPermission[],
  ) {
    if (!this.isAuthorized) {
      this.role = Roles.NO_ROLE;
    }

    this.initializeAbilities();
  }

  public build() {
    return this.builder.build();
  }

  private initializeAbilities() {
    this.assignApiDefinedPermissions();

    if (!hasCoRSet(this.cor)) return;
    this.createRoleAbilities();
  }

  private assignApiDefinedPermissions() {
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS, hasPermissionsToLegalDocs);
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO, hasPermissionsToPortfolio);
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.ADVISOR_COMMUNITY, hasPermissionsToAdvisorCommunity);
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.ADVISOR_ACADEMY, hasPermissionsToNedAcademy);
  }

  private assignPermission(
    action: AbilityAction,
    subject: AbilitySubject,
    permissionCheck: (permissions: ApiDefinedPermission[]) => boolean,
  ) {
    if (permissionCheck(this.apiDefinedPermissions)) {
      this.builder.can(action, subject);
    } else {
      this.builder.cannot(action, subject);
    }
  }

  private createRoleAbilities() {
    const roleAbilitiesCreators: Map<Roles, RoleAbilityBuilderFunction> = new Map([
      [Roles.FOUNDER, createFounderAbility],
      [Roles.INVESTOR, createInvestorAbility],
      [Roles.INVESTOR_NED, createInvestorNedAbility],
      [Roles.NED, createNedAbility],
      [Roles.NO_ROLE, createNoRoleAbility],
    ]);

    const roleAbilityCreator = roleAbilitiesCreators.get(this.role);

    if (!roleAbilityCreator) {
      throw new Error(`RoleAbility: RoleAbilityCreator for role ${this.role} not found`);
    }

    roleAbilityCreator(this.builder, {
      accountStatus: this.accountStatus,
      products: this.products,
      cor: this.cor,
      onboarding: this.onboarding,
      apiDefinedPermissions: this.apiDefinedPermissions,
    });
  }
}
