import { Roles } from '@/domain/accounts/roles';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { V2Links } from '@/v2-router/const/links';
import {
  GET_PROGRESS_REPORT_BOOLEAN_METRIC_VALUE_CACHE_KEY,
  getProgressReportBooleanMetricValue,
} from '@pages/content/progress-report/api/get-progress-report-boolean-metric-value/get-progress-report-boolean-metric-value.action';
import {
  GET_BOOLEAN_METRIC_VALUE_QUERY_CACHE_KEY,
  getBooleanMetricValue,
} from '@pages/content/pulse/api/get-boolean-metric-value/get-boolean-metric-value.action';
import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { metricIdQueryParam } from '@pages/content/pulse/constants';
import { dashboardIdParam } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { useQuery } from '@tanstack/react-query';
import { Tooltip } from 'antd';
import { stringify } from 'query-string';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import DES from '../../../../../dashboard-element/dashboard-element.styles';
import ChangeSize, { Size } from '../change-size/change-size';
import { DeleteMetric } from '../delete-modal/delete-metric';
import { DragIcon } from '../drag-icon/drag-icon';
import TriggerManagement from '../trigger-management/trigger-management';
import { Value } from './parts/value/value';

import S from './boolean-metric.styles';

type BooleanMetricProps = {
  name: string;
  metricId: string;
  triggerSet: boolean;
  allowSetNotifications: boolean;
  followMode: boolean;
  reportMode: boolean;
  onRemove?: () => void;
  dashboardId?: string;
  onSizeChange: (size: Size, preventSmall?: boolean) => void;
  isDesktop: boolean;
  isExternal: boolean;
};

export const BooleanMetric = ({
  name,
  metricId,
  allowSetNotifications,
  followMode,
  reportMode,
  onRemove,
  dashboardId,
  onSizeChange,
  isDesktop,
}: BooleanMetricProps) => {
  const navigate = useNavigate();
  const { hash = '' } = useParams() as { hash: string };

  const password = window.localStorage.getItem(hash);

  const {
    state: { userRole },
  } = useUserAccount();

  const hasInvestorPrivileges = userRole === Roles.INVESTOR || userRole === Roles.INVESTOR_NED;

  const { data: response } = useQuery(
    [
      reportMode
        ? GET_PROGRESS_REPORT_BOOLEAN_METRIC_VALUE_CACHE_KEY(metricId)
        : GET_BOOLEAN_METRIC_VALUE_QUERY_CACHE_KEY(metricId),
    ],
    reportMode ? getProgressReportBooleanMetricValue(hash, password, metricId) : getBooleanMetricValue(metricId),
    { refetchOnWindowFocus: !reportMode },
  );

  const goToManage = () => {
    navigate({
      search: stringify({ [metricIdQueryParam]: metricId, [dashboardIdParam]: dashboardId }),
      pathname: V2Links.founderMetrics({ mode: 'metrics' }),
    });
  };

  const isEditingMode = !followMode && !reportMode;

  const value = response?.data?.value ?? null;
  const note = response?.data?.note ?? null;
  const metric = <Value note={note} value={value} />;

  const HeaderNav = (
    <DES.MetricIcons hoverEffectDisabled={!isEditingMode}>
      {isEditingMode && isDesktop && <ChangeSize onClick={onSizeChange} disableSmall={false} />}

      {(() => {
        if (reportMode) return null;

        if (followMode && allowSetNotifications && hasInvestorPrivileges) {
          return (
            <TriggerManagement metricId={metricId} name={name} currentValue={value} metricType={MetricType.Boolean} />
          );
        }
        if (followMode) return null;
        return (
          <>
            <DES.Button data-testid={`${name.toLowerCase()}-edit-pen`} type="text" onClick={goToManage}>
              <DES.EditIcon />
            </DES.Button>
            {onRemove && isEditingMode && <DeleteMetric onRemove={onRemove} />}
          </>
        );
      })()}
    </DES.MetricIcons>
  );

  return (
    <>
      <S.Wrapper>
        <DES.Header>
          {isEditingMode && isDesktop && <DragIcon />}
          <Tooltip title={name}>
            <DES.Title>{name}</DES.Title>
          </Tooltip>
          {HeaderNav}
        </DES.Header>
        {metric}
      </S.Wrapper>
    </>
  );
};
