import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import type { ReactNode } from 'react';
import S from './register-wrapper.styles';

const RegisterWrapper = ({ children }: { children: ReactNode }) => {
  useEnforceTheme('dark');
  return (
    <S.OuterWrapper>
      <S.LeftHexagonalBackground />
      <S.RightHexagonalBackground />
      <S.Wrapper data-testid="register-wrapper">{children}</S.Wrapper>
    </S.OuterWrapper>
  );
};

export default RegisterWrapper;
