import { CHECKBOXES_LIST_ANIMATION_ID, LIST_ANIMATION_ELEMENT_ID } from './form';
import { useFormContext } from './form-context';
import { HighlightWrapper } from '@/parts/forms/highlight/highlight-wrapper';
import { Input } from '@parts/forms/highlight/form-elements.style';

import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import { V2Radio } from '@/components/ui/v2-radio/v2-radio';

import S from '@pages/content/onboarding/parts/personal-info-form/personal-info-form.styles';

export const AngelFormFields = () => {
  const {
    fieldLabels,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
    areFieldsDisabled,
    getIsChanged,
    submitForm,
    defaultRadioValue,
    values,
  } = useFormContext();

  const isLinkedinRadioSelected = values.radioLinkedinOrOccupation === 'linkedin';

  const [
    recentOccupationLabel,
    recentOccupationInfo,
    linkedinUrlInfo,
    recentCompanyLabel,
    verificationLabel,
    verificationLinkedinLabel,
    orLabel,
  ] = useTranslation([
    'investor-onboarding.personal.occupationLabel',
    'investor-onboarding.personal.occupationInfo',
    'investor-onboarding.personal.LinkedinUrlInfo',
    'investor-onboarding.personal.recentCompanyLabel',
    'investor-onboarding.personal.verificationLabel',
    'investor-onboarding.personal.verification.linkedin',
    'investor-onboarding.personal.verification.orLabel',
  ]);

  return (
    <>
      <S.RadioGroupContainer data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
        <S.RadioGroupContentWrapper>
          <S.RadioGroupTitle>{verificationLabel}</S.RadioGroupTitle>

          <S.RadioGroup variant="horizontal" defaultValue={defaultRadioValue}>
            <V2Radio
              variant="secondary"
              value="linkedin"
              onChange={(e) => setFieldValue('radioLinkedinOrOccupation', e.target.value)}
            >
              <S.LabelText>{verificationLinkedinLabel}</S.LabelText>
            </V2Radio>
            <S.Span>{orLabel}</S.Span>
            <V2Radio
              value="recent-occupation"
              variant="secondary"
              onChange={(e) => setFieldValue('radioLinkedinOrOccupation', e.target.value)}
            >
              <S.LabelText>{recentOccupationLabel}</S.LabelText>
            </V2Radio>
          </S.RadioGroup>
        </S.RadioGroupContentWrapper>
      </S.RadioGroupContainer>

      {isLinkedinRadioSelected ? (
        <S.FormikField
          wide
          label={{
            for: 'linkedinUrl',
            label: (
              <span>
                {fieldLabels.get('linkedinUrl')} <Tooltip title={linkedinUrlInfo} />
              </span>
            ),
          }}
          error={errors.linkedinUrl}
          touched={touched.linkedinUrl}
          data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
        >
          <HighlightWrapper highlight={getIsChanged('linkedinUrl')}>
            <Input
              {...getFieldProps('linkedinUrl')}
              onBlur={submitForm}
              $highlight={getIsChanged('linkedinUrl')}
              disabled={areFieldsDisabled}
              data-testid="personal-input-linkedinUrl"
              id="linkedinUrl"
              data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
            />
          </HighlightWrapper>
        </S.FormikField>
      ) : (
        <>
          <S.FormikField
            wide
            label={{
              label: (
                <span>
                  {recentOccupationLabel} <Tooltip title={recentOccupationInfo} />
                </span>
              ),
              for: 'recentOccupation',
            }}
            error={errors.recentOccupation}
            touched={touched.recentOccupation}
            data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
          >
            <HighlightWrapper highlight={getIsChanged('recentOccupation')}>
              <Input
                {...getFieldProps('recentOccupation')}
                onChange={(e) => setFieldValue('recentOccupation', e.target.value || null)}
                onBlur={submitForm}
                $highlight={getIsChanged('recentOccupation')}
                disabled={areFieldsDisabled}
                data-testid="personal-input-recentOccupation"
                id="recentOccupation"
              />
            </HighlightWrapper>
          </S.FormikField>

          <S.FormikField
            wide
            label={{
              label: <span>{recentCompanyLabel}</span>,
              for: 'recentCompany',
            }}
            error={errors.recentCompany}
            touched={touched.recentCompany}
            data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
          >
            <HighlightWrapper highlight={getIsChanged('recentCompany')}>
              <Input
                {...getFieldProps('recentCompany')}
                onChange={(e) => setFieldValue('recentCompany', e.target.value || null)}
                onBlur={submitForm}
                $highlight={getIsChanged('recentCompany')}
                disabled={areFieldsDisabled}
                data-testid="personal-input-recentCompany"
                id="recentCompany"
              />
            </HighlightWrapper>
          </S.FormikField>
        </>
      )}
    </>
  );
};
