import { useReducer, type ReactNode, type Reducer } from 'react';
import { FounderOnboardingContext, type FounderOnboardingState } from './founder-onboarding.context';
import { initialFounderOnboardingState, reducer, type FounderOnboardingActionType } from './founder-onboarding.reducer';

export const FounderOnboardingContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<FounderOnboardingState, FounderOnboardingActionType>>(
    reducer,
    initialFounderOnboardingState,
  );

  return <FounderOnboardingContext.Provider value={{ state, dispatch }}>{children}</FounderOnboardingContext.Provider>;
};
