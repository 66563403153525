import { UserActions } from '@context/user/user-account.reducer';
import { Roles } from '@domain/accounts/roles';
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
import type { RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';
import { type UserAccountMetadata } from './user-account.context';

export const finishLogin = (userId: string, userRole = Roles.NO_ROLE, products: SubscriptionProduct[] = []) => ({
  userId,
  userRole,
  products,
  type: UserActions.FINISH_LOGIN,
});

export const startLogin = () => ({
  type: UserActions.START_LOGIN,
});

export const setInitialized = () => ({
  type: UserActions.SET_INITIALIZED,
});

export const setUserOnboardingStatus = (status: Partial<RoleOnboardingStatus>) => ({
  type: UserActions.SET_USER_ONBOARDING_STATUS,
  onboarding: status,
});

export const finishLogout = () => ({
  type: UserActions.FINISH_LOGOUT,
});

export const startLogout = () => ({
  type: UserActions.START_LOGOUT,
});

export const setUserMetadata = (payload: UserAccountMetadata) => ({
  type: UserActions.SET_METADATA,
  metadata: payload,
});

export const setUserProducts = (products: SubscriptionProduct[]) => ({
  type: UserActions.SET_PRODUCTS,
  products,
});
