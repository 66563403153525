import { V2Links } from '@/v2-router/const/links';
import DropdownIcon from '@assets/icons/dropdown-blue-tick.svg?react';
import type { Dashboard } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Menu } from 'antd';
import { stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';
import { dashboardIdParam } from './dashboards-menu';
import S from './dashboards-menu.styles';

export const MobileDashboardsMenu = ({
  dashboards,
  onClickMenu,
  selectedDashboard,
  onAddClick,
}: {
  dashboards: Dashboard[];
  onClickMenu: Function;
  selectedDashboard: Dashboard | null;
  onAddClick: () => void;
}) => {
  const navigate = useNavigate();
  const [manageDataLabel, addDashboardLabel] = useTranslation([
    'founder.pulseDashboard.mobile.manageData',
    'founder.pulseDashboard.mobile.addDashboard',
  ]);

  const {
    state: {
      integrations: {
        codat: { isConnected },
      },
    },
  } = useUserAccount();

  const handleManageDataClick = () => {
    const linkToMetrics = V2Links.founderMetrics({ mode: isConnected ? 'integrated-metrics' : 'metrics' });

    const query = selectedDashboard?.id ? { [dashboardIdParam]: selectedDashboard?.id } : {};

    navigate(`${linkToMetrics}?${stringify(query)}`);
  };

  const [defaultDashboard] = dashboards;
  const MobileMenu = (
    <Menu selectedKeys={[selectedDashboard?.id ?? '']}>
      {dashboards.map((dashboard) => (
        <Menu.Item
          data-testid="mobile-dashboard-menu-item"
          key={dashboard.id}
          onClick={() => onClickMenu(dashboard.id)}
        >
          {dashboard.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <S.MobileMenuPanel>
      <S.MobileDropdownTabs>
        <S.Dropdown overlay={MobileMenu}>
          <S.MobileDropdownTabs onClick={(e) => e.preventDefault()}>
            <S.MobileSelectedTab>{selectedDashboard?.name ?? defaultDashboard?.name}</S.MobileSelectedTab>
            <DropdownIcon data-testid="dropdown-blue-tick" />
          </S.MobileDropdownTabs>
        </S.Dropdown>

        <S.MobileAddBtn
          onClick={onAddClick}
          type="link"
          data-testid="founder-pulse-add-dashboard-trigger-button-mobile"
        >
          {addDashboardLabel}
        </S.MobileAddBtn>
      </S.MobileDropdownTabs>

      <S.MobileButton type="link" onClick={handleManageDataClick}>
        {manageDataLabel}
      </S.MobileButton>
    </S.MobileMenuPanel>
  );
};
