import advisor from './languages/en-GB/advisor.json';
import advisors from './languages/en-GB/advisors.json';
import checkout from './languages/en-GB/checkout.json';
import confirmModal from './languages/en-GB/confirm-modal.json';
import connections from './languages/en-GB/connections.json';
import dashboards from './languages/en-GB/dashboards.json';
import oldMessages from './languages/en-GB/en.json';
import errors from './languages/en-GB/error.json';
import homepage from './languages/en-GB/homepage.json';
import inbox from './languages/en-GB/inbox.json';
import infoBanners from './languages/en-GB/info-banners.json';
import legal from './languages/en-GB/legal.json';
import lens from './languages/en-GB/lens.json';
import messages from './languages/en-GB/messages.json';
import metrics from './languages/en-GB/metrics.json';
import navigation from './languages/en-GB/navigation.json';
import notifications from './languages/en-GB/notifications.json';
import onboarding from './languages/en-GB/onboarding.json';
import portfolio from './languages/en-GB/portfolio.json';
import profile from './languages/en-GB/profile.json';
import pulse from './languages/en-GB/pulse.json';
import qualifyingQuestions from './languages/en-GB/qualifying-questions.json';
import referrals from './languages/en-GB/referrals.json';
import report from './languages/en-GB/report.json';
import roles from './languages/en-GB/roles.json';
import splashScreen from './languages/en-GB/splash-screen.json';
import start from './languages/en-GB/start.json';
import upgradeFreemiumModal from './languages/en-GB/upgrade-freemium-modal.json';
import utility from './languages/en-GB/utility.json';

const enGB = {
  ...oldMessages,
  ...profile,
  ...errors,
  ...messages,
  ...pulse,
  ...navigation,
  ...roles,
  ...upgradeFreemiumModal,
  ...confirmModal,
  ...lens,
  ...inbox,
  ...splashScreen,
  ...checkout,
  ...notifications,
  ...utility,
  ...metrics,
  ...report,
  ...legal,
  ...start,
  ...portfolio,
  ...dashboards,
  ...onboarding,
  ...referrals,
  ...advisors,
  ...qualifyingQuestions,
  ...infoBanners,
  ...homepage,
  ...connections,
  ...advisor,
} as const;

export const EN_GB_TRANSLATIONS = enGB;
