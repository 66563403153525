import type { ProductDetailsDraft } from '@domain/subscriptions/product-details';
import { createContext, type Dispatch } from 'react';
import type { CheckoutActionType, CheckoutState } from './checkout.reducer';

export type CheckoutStateApi = {
  state: CheckoutState;
  computed: {
    currentStep: 1 | 2;
    selectedProduct: ProductDetailsDraft | null;
    productGrossPrice: string;
    productNetPrice: string;
    productVat: string;
    discountValue: string;
    currentVat: string;
    currentNetPrice: string;
    currentGrossPrice: string;
    finalPrice: string;
  };
  dispatch: Dispatch<CheckoutActionType>;
};

export const checkoutInitialState: CheckoutState = {
  isLoading: true,
  loadingError: null,
  products: [],
  selectedProductValue: '',
  discount: null,
};

export const CheckoutContext = createContext<CheckoutStateApi | CheckoutState>(checkoutInitialState);
