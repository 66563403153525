import {
  GET_CODAT_CONSOLE_URL,
  getCodatConsoleUrlAction,
} from '@/modules/founder/report/api/get-codat-console-url/get-codat-console-url.action';
import type { CodatRequestContextValue } from '@/pages/content/pulse/api/connect-codat/connect-codat.action';
import { useQuery } from '@tanstack/react-query';

type Config = {
  renderContext: CodatRequestContextValue;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export const useCodatAddIntegrationUrl = ({ renderContext, enabled = true, refetchOnWindowFocus = false }: Config) => {
  const queryResult = useQuery([GET_CODAT_CONSOLE_URL, renderContext], getCodatConsoleUrlAction(renderContext), {
    enabled,
    refetchOnWindowFocus,
  });

  return {
    codatConsoleUrl: queryResult.data?.data.consoleUrl,
    isLoadingCodatConsoleUrl: queryResult.isLoading,
    ogQuery: { ...queryResult },
  };
};
