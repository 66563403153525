import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UsePaginationProps {
  totalItems: number;
  itemsPerPage?: number;
  defaultPage?: number;
  pageParam?: string;
}

export interface OrderParam {
  [key: string]: 'asc' | 'desc';
}

export const usePagination = ({ totalItems, itemsPerPage = 20, defaultPage = 1, pageParam }: UsePaginationProps) => {
  const query = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (pageParam && !query[pageParam]) {
      navigate(
        {
          search: `?${queryString.stringify({ ...query, [pageParam]: defaultPage })}`,
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line
  }, []);

  const initialCurrentPage = pageParam && query[pageParam] ? Number(query[pageParam]) : defaultPage;
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(currentItemsPerPage);
  const maxPages = Math.ceil(totalItems / currentItemsPerPage);
  const [orderParam, setOrderParam] = useState<OrderParam | null>(null);

  const setMeta = (newPage: number, size?: number) => {
    const newStart = (newPage - 1) * (size || currentItemsPerPage);
    setStart(newStart);
    setLimit(size || currentItemsPerPage);

    if (size) {
      setCurrentItemsPerPage(size);
    }
  };

  const setPage = (incomingPage: number, size?: number) => {
    if (incomingPage < 1 || incomingPage > maxPages) {
      return;
    }

    setCurrentPage(incomingPage);
    setMeta(incomingPage, size);

    if (pageParam) {
      navigate(
        {
          search: `?${queryString.stringify({ ...query, [pageParam]: incomingPage })}`,
        },
        { replace: true },
      );
    }
  };

  return {
    currentPage,
    setPage,
    limit,
    start,
    orderParam,
    setOrderParam,
    maxPages,
  };
};
