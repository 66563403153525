import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { Navigate, matchRoutes, useLocation, useParams } from 'react-router-dom';
import { V2Links } from '../const/links';
import { redirectFromLegacy } from '../const/redirect-from-legacy';
import { LegacyRoutes, V2Routes } from '../const/routes';
import { legacyRoutes } from '../routes/legacy-routes';

export const HandleLegacyRoute = () => {
  const location = useLocation();
  const params = useParams();
  const searchQueryParams = useQueryParams();
  const { state } = useUserAccount();

  const match = matchRoutes(legacyRoutes, location.pathname)?.[0].route.path as LegacyRoutes | undefined;

  if (match && match.length > 0) {
    const result = redirectFromLegacy[match]?.[state.userRole] ?? V2Routes.ERROR_404;
    const query = location.search;
    let destination = `${result}`;

    Object.keys(params).forEach((key) => {
      destination = destination.replace(`:${key}`, params[key] ?? '');
    });

    // replace url segments with matching query params for links that were converted as below
    // e.g. /:mode?id=123 -> /:mode/:id
    Object.keys(searchQueryParams).forEach((key) => {
      destination = destination.replace(`:${key}`, searchQueryParams[key] ?? '');
    });

    return <Navigate to={`${destination}${query}`} replace />;
  }

  return <Navigate to={V2Links.error404()} replace />;
};
