import { useChangedFields } from '@/pages/content/onboarding/hooks/use-changed-fields';
import S from '@pages/content/onboarding/parts/personal-info-form/personal-info-form.styles';
import {
  updatePersonalDetailsAction,
  type InvestorPersonalDetailsUpdatePayload,
} from '@pages/content/profile/investor/api/personal-details/personal-details';
import { GenderType, InvestorType, type IGetInvestorPersonalDetails } from '@pages/content/profile/investor/api/types';
import message from '@parts/message/message';
import { useMutation } from '@tanstack/react-query';
import { type AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useFormik } from 'formik';
import { useMemo, useRef, useState } from 'react';
import type { Any } from 'src/types';
import { useOnFormValidationChangeMessage } from '../../../hooks/use-on-form-validation-change-message';
import { useValidationSchema } from './validation-schema';
import { FormContext } from './form-context';
import type { FormDataContext } from './form-types';
import { InstitutionalFormFields } from './institutional-form-fields';
import { SharedFormFields } from './shared-form-fields';
import { AngelFormFields } from './angel-form-fields';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

export const LIST_ANIMATION_ELEMENT_ID = 'form-field';
export const CHECKBOXES_LIST_ANIMATION_ID = 'checkbox-form-field';

const INITIAL_CHECKBOXES_ANIMATION_DELAY = 700;
const CHECKBOXES_ANIMATION_DELAY = 100;

export interface FormikFormData {
  firstName: string;
  lastName: string;
  gender: GenderType | null;
  recentOccupation: string | null;
  recentCompany: string | null;
  companyName: string | null;
  linkedinUrl: string | null;
  profilePicturePath: string | null;
  profilePictureUrl: string | null;
  radioLinkedinOrOccupation: string | null;
}

interface PersonalInfoFormProps {
  personalDetails: IGetInvestorPersonalDetails;
  isPersonalDetailsLoading: boolean;
  investorType: InvestorType;
  refetchPersonalDetails: () => void;
  onFormValidStateChange: (validStatus: boolean, error: string | null) => void;
}

export const PersonalInfoForm = ({
  personalDetails,
  isPersonalDetailsLoading,
  refetchPersonalDetails,
  onFormValidStateChange,
  investorType,
}: PersonalInfoFormProps) => {
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);
  const { getIsChanged, resetChangedWithDelay, setChanged } = useChangedFields();

  const defaultRadioValue = personalDetails?.recentOccupation ? 'recent-occupation' : 'linkedin';

  const [checkboxesAnimationDelay, setCheckboxesAnimationDelay] = useState<number>(INITIAL_CHECKBOXES_ANIMATION_DELAY);

  const [
    firstNameLabel,
    lastNameLabel,
    genderLabel,
    recentOccupationLabel,
    linkedinUrlLabel,
    companyNameLabel,
    recentCompanyLabel,
  ] = useTranslation([
    'investor-onboarding.personal.firstNameLabel',
    'investor-onboarding.personal.LastNameLabel',
    'gender.select.title',
    'investor-onboarding.personal.occupationLabel',
    'investor-onboarding.personal.linkedinUrlLabel',
    'investor-onboarding.personal.companyNameLabel',
    'investor-onboarding.personal.recentCompanyLabel',
  ]);

  const { mutateAsync: updatePersonalDetails, isLoading: isUpdatingPersonalDetails } = useMutation(
    updatePersonalDetailsAction,
    {
      onSuccess: (_, variables) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleHighlight(variables as Any);
        refetchPersonalDetails();
      },
      onError: (err: AxiosError) => {
        message.error({ content: getServerError(err) });
      },
    },
  );

  const formik = useFormik<FormikFormData>({
    initialValues: {
      firstName: personalDetails?.firstName ?? '',
      lastName: personalDetails?.lastName ?? '',
      gender: personalDetails?.gender ?? null,
      linkedinUrl: personalDetails?.linkedinUrl ?? null,
      companyName: personalDetails?.companyName ?? null,
      recentOccupation: personalDetails?.recentOccupation ?? null,
      recentCompany: personalDetails?.recentCompany ?? null,
      profilePictureUrl: personalDetails?.profilePicture?.url ?? null,
      profilePicturePath: personalDetails?.profilePicture?.path ?? null,
      radioLinkedinOrOccupation: defaultRadioValue ?? null,
    },
    onSubmit({
      radioLinkedinOrOccupation,
      profilePicturePath,
      profilePictureUrl,
      companyName,
      recentOccupation,
      recentCompany,
      ...values
    }) {
      const payload: InvestorPersonalDetailsUpdatePayload = {
        ...values,
        aboutMe: null,
        profilePhotoPath: profilePicturePath,
        ...(investorType === InvestorType.Institutional ? { companyName } : { recentOccupation, recentCompany }),
        phoneNumber: personalDetails?.phoneNumber || '',
        twitterUrl: (personalDetails?.twitterUrl || null) as string,
        linkedinUrl: values.linkedinUrl,
      };

      updatePersonalDetails(payload);
    },
    enableReinitialize: true,
    validationSchema: useValidationSchema(investorType),
  });

  useListEntryRevealAnimation(
    CHECKBOXES_LIST_ANIMATION_ID,
    { delay: checkboxesAnimationDelay },
    { deps: [formik.values.radioLinkedinOrOccupation], shouldReinit: () => true },
    false,
    () => setCheckboxesAnimationDelay(CHECKBOXES_ANIMATION_DELAY),
  );

  const handleHighlight = (variables: FormikFormData) => {
    setChanged(variables as Any, formik.initialValues as Any);
    resetChangedWithDelay();
  };

  const fieldLabels = new Map<keyof FormikFormData, string>([
    ['firstName', firstNameLabel],
    ['lastName', lastNameLabel],
    ['gender', genderLabel],
    ['recentOccupation', recentOccupationLabel],
    ['companyName', companyNameLabel],
    ['recentCompany', recentCompanyLabel],
    ['linkedinUrl', linkedinUrlLabel],
    ['profilePicturePath', ''],
    ['profilePictureUrl', ''],
  ]);

  useOnFormValidationChangeMessage(formik, fieldLabels, onFormValidStateChange);

  const invisibleInputRef = useRef<HTMLInputElement>(null);

  const areFieldsDisabled = isPersonalDetailsLoading && isUpdatingPersonalDetails;

  const formContextData: FormDataContext = useMemo(
    () => ({
      fieldLabels,
      areFieldsDisabled,
      invisibleInputRef,
      defaultRadioValue,
      errors: formik.errors,
      touched: formik.touched,
      values: formik.values,
      isValid: formik.isValid,
      setFieldValue: formik.setFieldValue,
      getFieldProps: formik.getFieldProps,
      submitForm: formik.submitForm,
      setFieldTouched: formik.setFieldTouched,
      getIsChanged,
    }),
    [formik, fieldLabels, areFieldsDisabled, defaultRadioValue, getIsChanged],
  );

  return (
    <FormContext.Provider value={formContextData}>
      <S.Form>
        <SharedFormFields />

        {investorType === InvestorType.Angel && <AngelFormFields />}

        {investorType === InvestorType.Institutional && <InstitutionalFormFields />}
      </S.Form>
    </FormContext.Provider>
  );
};
