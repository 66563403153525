import { LIST_ANIMATION_ELEMENT_ID } from './form';
import { useFormContext } from './form-context';
import { HighlightWrapper } from '@/parts/forms/highlight/highlight-wrapper';
import { Input } from '@parts/forms/highlight/form-elements.style';

import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';

import S from '@pages/content/onboarding/parts/personal-info-form/personal-info-form.styles';

export const InstitutionalFormFields = () => {
  const { fieldLabels, getFieldProps, setFieldValue, touched, errors, areFieldsDisabled, getIsChanged, submitForm } =
    useFormContext();

  const [linkedinUrlInfo] = useTranslation(['investor-onboarding.personal.LinkedinUrlInfo']);

  return (
    <>
      <S.FormikField
        wide
        label={{
          label: (
            <span>
              {fieldLabels.get('companyName')} <S.RequiredAsterisk>*</S.RequiredAsterisk>
            </span>
          ),
          for: 'companyName',
        }}
        error={errors.companyName}
        touched={touched.companyName}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={getIsChanged('companyName')}>
          <Input
            {...getFieldProps('companyName')}
            onChange={(e) => setFieldValue('companyName', e.target.value || null)}
            onBlur={submitForm}
            $highlight={getIsChanged('companyName')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-companyName"
            id="companyName"
          />
        </HighlightWrapper>
      </S.FormikField>
      <S.FormikField
        wide
        label={{
          for: 'linkedinUrl',
          label: (
            <span>
              {fieldLabels.get('linkedinUrl')} <Tooltip title={linkedinUrlInfo} />
            </span>
          ),
        }}
        error={errors.linkedinUrl}
        touched={touched.linkedinUrl}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={getIsChanged('linkedinUrl')}>
          <Input
            {...getFieldProps('linkedinUrl')}
            onBlur={submitForm}
            $highlight={getIsChanged('linkedinUrl')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-linkedinUrl"
            id="linkedinUrl"
          />
        </HighlightWrapper>
      </S.FormikField>
    </>
  );
};
