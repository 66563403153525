import ConnectionsIcon from '@assets/icons/visibility-connections.svg';
import PrivateIcon from '@assets/icons/visibility-private.svg';
import CloseConnectionsIcon from '@assets/icons/visibility-union.svg';
import {
  GET_FOUNDER_DASHBOARD_CACHE_KEY,
  VisibilityMode,
} from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { DropdownList } from '@parts/dropdown-list/dropdown-list';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Menu, Tooltip } from 'antd';
import { setDashboardVisbilityAction } from '../../../api/set-dashboard-visibility.action';
import S from './visibility.styles';

export const Visibility = ({
  visibility,
  dashboardId,
  hasCloseConnection = false,
}: {
  visibility: VisibilityMode;
  dashboardId: string;
  hasCloseConnection?: boolean;
}) => {
  const [privateLabel, connectionsLabel, closeConnectionsLabel, closeConnectionTooltipLabel, visibleToTheLabel] =
    useTranslation([
      'dashboards.visibility.private',
      'dashboards.visibility.connections',
      'dashboards.visibility.closeConnections',
      'dashboards.visibility.closeConnections.tooltip',
      'dashboards.visibility.visibleToThe',
    ]);

  const modes: { [key in VisibilityMode]: { text: string; icon: string } } = {
    [VisibilityMode.Private]: {
      text: privateLabel,
      icon: PrivateIcon,
    },
    [VisibilityMode.Connections]: {
      text: connectionsLabel,
      icon: ConnectionsIcon,
    },
    [VisibilityMode.CloseConnections]: {
      text: closeConnectionsLabel,
      icon: CloseConnectionsIcon,
    },
  };

  const queryClient = useQueryClient();

  const MenuOptions = () => (
    <Menu>
      {Object.entries(modes).map(([key, { text, icon }]) =>
        !hasCloseConnection && key === VisibilityMode.CloseConnections ? (
          <S.MenuItem key={`menu-options-${key}`} disabled>
            <Tooltip title={closeConnectionTooltipLabel}>
              <img src={icon} alt={text} loading="lazy" />
              <S.Option>{text}</S.Option>
            </Tooltip>
          </S.MenuItem>
        ) : (
          <S.MenuItem
            key={`menu-options-${key}`}
            onClick={async () => {
              await setDashboardVisbilityAction(dashboardId, { visibility: key as VisibilityMode });
              queryClient.invalidateQueries([GET_FOUNDER_DASHBOARD_CACHE_KEY, dashboardId]);
            }}
          >
            <img src={icon} alt={text} loading="lazy" />

            <S.Option>{text}</S.Option>
          </S.MenuItem>
        ),
      )}
    </Menu>
  );

  const menuOpener = () => (
    <S.Button>
      <img src={modes[visibility].icon} alt={modes[visibility].text} loading="lazy" /> {modes[visibility].text}
    </S.Button>
  );

  return (
    <S.Wrapper data-testid="dropdown-visibility">
      <S.Text>This dashboard is {visibility !== VisibilityMode.Private && visibleToTheLabel}</S.Text>
      <DropdownList overlay={MenuOptions} dropdownInvokerElement={menuOpener()} />
    </S.Wrapper>
  );
};
