import { hasFinishedInvestorOnboarding } from '@/abilities/ability/abilities/utils/has-finished-investor-onboarding';
import { hasNotApplicableInvestorOnboarding } from '@/abilities/ability/abilities/utils/has-not-applicable-investor-onboarding';
import { Roles } from '@/domain/accounts/roles';
import type { RoleOnboardingStatus } from '@/pages/auth/api/account-data/account-data.types';

export const hasFinishedOnboarding = (role: Roles, onboarding: RoleOnboardingStatus): boolean => {
  if (role === Roles.INVESTOR)
    return hasFinishedInvestorOnboarding(onboarding) || hasNotApplicableInvestorOnboarding(onboarding);
  if (role === Roles.NED)
    return hasFinishedInvestorOnboarding(onboarding) || hasNotApplicableInvestorOnboarding(onboarding);
  return false;
};
