import { useGetFounderLearningProgress } from '@/modules/founder/homepage/hooks/use-get-founder-learning-progress';
import { toPascalCase } from '@/utils/fns/to-pascal-case';
import { V2Links } from '@/v2-router/const/links';
import { useTipsContent } from '@pages/content/onboarding/founder/hooks/use-tips-content';
import {
  type FounderOnboardingPathName,
  type FounderOnboardingPathStep,
} from '@pages/content/onboarding/founder/paths';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import Tips from '@pages/content/onboarding/parts/tips/tips';
import message from '@parts/message/message';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useFounderOnboardingContext } from '@utils/hooks/use-onboarding-context/use-founder-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useNavigate, useParams } from 'react-router-dom';
import { setFounderLearningStepFinished } from '../api/set-learning-step-finished.action';
import { paths } from '../paths.config';
import {
  isLastStep as assertIsLastStep,
  getNextStep,
  getPathLength,
  getPrevStep,
  getStep,
  getStepIndex,
} from '../paths.helpers';
import type { FounderLearningStep } from '../types/learning-steps';

export const FounderOnboardingStepPage = () => {
  const navigate = useNavigate();
  const { state: onboardingState } = useFounderOnboardingContext();
  const { path, step } = useParams<{ path: FounderOnboardingPathName; step: string }>();

  const {
    founderLearningProgressData,
    isFounderOnboardingProgressLoading,
    isFounderOnboardingProgressFetching,
    refetchFounderLearningProgress,
  } = useGetFounderLearningProgress();

  const { mutateAsync: finishOnboardingStep, isLoading: isFinishOnboardingStepPending } = useMutation(
    setFounderLearningStepFinished,
    {
      async onSuccess() {
        await refetchFounderLearningProgress();
      },
      onError(err: AxiosError) {
        message.error({ content: getServerError(err) });
        refetchFounderLearningProgress();
      },
    },
  );

  const stepErrors = onboardingState.stepErrors.filter((item) => item.step === (step as FounderOnboardingPathStep));

  const [tipsTitleLabel] = useTranslation(['onboarding.tips.title']);
  // TODO - REFACTOR to get rid of !
  const tooltipContent = useTipsContent(step!);
  const nextStep = getNextStep(paths, path!, step);
  const prevStep = getPrevStep(paths, path!, step!);
  const isLastStep = assertIsLastStep(paths, path!, step!);
  const currentStepIndex = getStepIndex(paths, path!, step!);
  const currentStep = getStep(paths, path!, step!);

  const goToNextStep = () => navigate(V2Links.onboardingFounderStep({ path: path!, step: nextStep.key }));
  const goToPrevStep = () => {
    const prevPath = prevStep
      ? V2Links.onboardingFounderStep({ path: path!, step: prevStep.key })
      : V2Links.founderHomepage();
    navigate(prevPath);
  };

  const prevButtonHandler = async () => {
    goToPrevStep();
  };

  const nextButtonHandler = async () => {
    const stepError = onboardingState.stepErrors.find((item) => item.step === (step as FounderOnboardingPathStep));
    if (stepError) {
      message.error({ content: stepError?.error ?? '' });
      return;
    }

    const isStepFinished = founderLearningProgressData.includes(toPascalCase(step!) as FounderLearningStep);
    if (!isStepFinished) {
      try {
        await finishOnboardingStep(toPascalCase(step!) as FounderLearningStep);
      } catch {
        return;
      }
    }

    if (isLastStep) {
      navigate(V2Links.founderHomepage());
      return;
    }

    goToNextStep();
  };

  const disableNextButton =
    isFounderOnboardingProgressLoading ||
    isFounderOnboardingProgressFetching ||
    isFinishOnboardingStepPending ||
    stepErrors.some((item) => item.disableButton === true);

  const finishLaterHandler = () => {
    navigate(V2Links.founderHomepage());
  };

  const { Content } = currentStep ?? { Content: () => null };
  return (
    <OnboardingLayout
      currentStep={currentStepIndex + 1}
      totalSteps={getPathLength(paths, path!)}
      asideContent={<>{tooltipContent && <Tips title={tipsTitleLabel} content={tooltipContent} />}</>}
      onPreviousClick={prevButtonHandler}
      onNextClick={nextButtonHandler}
      lastPage={isLastStep}
      nextDisabled={disableNextButton}
      finishLater
      onFinishLater={finishLaterHandler}
    >
      <Content currentPage={currentStepIndex + 1} endPage={getPathLength(paths, path!)} />
    </OnboardingLayout>
  );
};
