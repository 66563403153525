import { AbilityAction, AbilitySubject, type RoleAbilityBuilderFunction } from '../types';
import { hasPermissionsToNedAcademy } from './utils/has-permission-to-ned-academy';

const createActionAbilities: RoleAbilityBuilderFunction = (builder, { apiDefinedPermissions }) => {
  const { can, cannot } = builder;

  if (hasPermissionsToNedAcademy(apiDefinedPermissions)) {
    can(AbilityAction.ACCESS, AbilitySubject.ADVISOR_ACADEMY);
  } else {
    cannot(AbilityAction.ACCESS, AbilitySubject.ADVISOR_ACADEMY);
  }

  return builder;
};

export const createInvestorNedAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  return createActionAbilities(builder, attributes);
};
