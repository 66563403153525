import { LinkButton } from '@parts/link-button/link-button';
import { Switch as SwitchBase } from 'antd';
import styled from 'styled-components';

const checkoutContainerWidth = '900px';
const checkoutContainerWideWidth = '1200px';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.checkoutBackground};
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WrapperContainer = styled.div<{ $wide?: boolean }>`
  width: 100%;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.xbase};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    max-width: ${({ $wide }) => ($wide ? checkoutContainerWideWidth : checkoutContainerWidth)};
  }
`;

const Content = styled.div`
  height: 590px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.medium} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  > a {
    color: ${({ theme }) => theme.baseColors.White};
    display: block;
    font-size: ${({ theme }) => theme.fontSize.xxsmall};
    text-align: center;
    margin: 35px 0 ${({ theme }) => theme.spacing.medium} 0;
  }
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  text-align: center;

  > a {
    color: ${({ theme }) => theme.baseColors.White};
    display: block;
    font-size: ${({ theme }) => theme.fontSize.xxsmall};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    justify-content: center;
  }
`;

const SwitchWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const Switch = styled(SwitchBase)`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.small};

  &[aria-checked='true'] {
    background: ${({ theme }) => theme.gradient.checkoutButtons};
  }

  span {
    font-size: 9px;
  }
`;

const Vat = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: inline-block;
`;

const Back = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.baseColors.White};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
`;

export const NarrowLink = styled(LinkButton)`
  margin-top: ${({ theme }) => theme.spacing.base};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  max-width: 350px;
  padding: ${({ theme }) => theme.spacing.small};
  text-align: center;

  background: ${({ theme }) => theme.baseColors.Pink};
  border-color: ${({ theme }) => theme.baseColors.Pink};
  border-radius: ${({ theme }) => theme.radius.medium};

  svg {
    transition: transform 0.5s;
  }

  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.baseColors.White};
    border-color: ${({ theme }) => theme.baseColors.White};
    color: ${({ theme }) => theme.baseColors.Blue400};

    &:not(:disabled) {
      transform: scale(1.01);
    }

    svg {
      transform: translateX(5px);
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
  }
`;

export default {
  Wrapper,
  WrapperContainer,
  Content,
  Controls,
  Switch,
  Vat,
  SwitchWrapper,
  Back,
  NarrowLink,
};
