import { Avatar } from '@parts/avatar/avatar';
import message from '@parts/message/message';
import { uploadProfileAvatar } from '@pages/content/profile/investor/api/upload-profile-avatar/upload-profile-avatar';
import { LIST_ANIMATION_ELEMENT_ID } from './form';
import { useFormContext } from './form-context';
import { HighlightWrapper } from '@/parts/forms/highlight/highlight-wrapper';
import { Input } from '@parts/forms/highlight/form-elements.style';
import { Select } from '@parts/select/select';
import HiddenImageInput from '@pages/content/parts/hidden-file-input/hidden-file-input';
import { GenderType } from '@/pages/content/profile/investor/api/types';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { setUserMetadata } from '@context/user/user-account.actions';

import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import S from '@pages/content/onboarding/parts/personal-info-form/personal-info-form.styles';

export const SharedFormFields = () => {
  const {
    fieldLabels,
    getFieldProps,
    setFieldValue,
    touched,
    errors,
    values,
    isValid,
    areFieldsDisabled,
    invisibleInputRef,
    getIsChanged,
    submitForm,
    setFieldTouched,
  } = useFormContext();
  const { dispatch, state } = useUserAccount();

  const [avatarInfoLabelL1, avatarInfoLabelL2, uploadLabel, manLabel, womanLabel, nonBinaryLabel, preferNotToSayLabel] =
    useTranslation([
      'onboarding.avatar.info.l1',
      'onboarding.avatar.info.l2',
      'onboarding.avatar.upload',
      'gender.select.man',
      'gender.select.woman',
      'gender.select.nonBinary',
      'gender.select.preferNotToSay',
    ]);

  const { mutateAsync: uploadPhoto, isLoading: isUploadingPhoto } = useMutation(uploadProfileAvatar, {
    onSuccess: (res) => {
      setFieldValue('profilePicturePath', res.data.filePath);
      setFieldValue('profilePictureUrl', res.data.fileUrl);
      dispatch(
        setUserMetadata({
          ...state,
          avatarUrl: res.data.fileUrl || null,
        }),
      );
      submitForm();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const genderLabels = new Map<GenderType, string>([
    [GenderType.Man, manLabel],
    [GenderType.Woman, womanLabel],
    [GenderType.NonBinary, nonBinaryLabel],
    [GenderType.PreferNotToSay, preferNotToSayLabel],
  ]);

  return (
    <>
      <S.FormikField
        wide
        label={{
          for: 'profilePicturePath',
          label: fieldLabels.get('profilePicturePath') ?? '',
        }}
        error={errors.profilePicturePath}
        touched={touched.profilePicturePath}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <S.AvatarUploader>
          <HiddenImageInput submitFn={(formData: FormData) => uploadPhoto({ formData })} ref={invisibleInputRef} />
          {isUploadingPhoto ? (
            <S.AvatarSpin size="large" />
          ) : (
            <Avatar
              letter={values.firstName?.charAt(0) ?? 'I'}
              size={75}
              avatarUrl={values.profilePictureUrl}
              pastelBackground
            />
          )}
          <S.Controls>
            <S.AvatarUploadButton
              role="button"
              onClick={() => invisibleInputRef.current?.click()}
              data-testid="personal-avatar-upload-button"
            >
              {uploadLabel}
            </S.AvatarUploadButton>
            <S.AvatarTextInfo>
              {avatarInfoLabelL1}
              <br />
              {avatarInfoLabelL2}
            </S.AvatarTextInfo>
          </S.Controls>
        </S.AvatarUploader>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('firstName') ?? '',
          for: 'firstName',
        }}
        error={errors.firstName}
        touched={touched.firstName}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={getIsChanged('firstName')}>
          <Input
            {...getFieldProps('firstName')}
            onBlur={submitForm}
            $highlight={getIsChanged('firstName')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-firstName"
            id="firstName"
          />
        </HighlightWrapper>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('lastName') ?? '',
          for: 'lastName',
        }}
        error={errors.lastName}
        touched={touched.lastName}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={getIsChanged('lastName')}>
          <Input
            {...getFieldProps('lastName')}
            onBlur={submitForm}
            $highlight={getIsChanged('lastName')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-lastName"
            id="lastName"
          />
        </HighlightWrapper>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('gender') ?? '',
          for: 'gender',
        }}
        error={errors.gender}
        touched={touched.gender}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={getIsChanged('gender')}>
          <Select
            data-testid="personal-select-gender"
            {...getFieldProps('gender')}
            value={values.gender ?? undefined}
            onChange={(value) => {
              setFieldTouched('gender', true);
              setFieldValue('gender', value || null);
              if (isValid) submitForm();
            }}
          >
            <Select.Option key="empty" value="">
              ---
            </Select.Option>
            {[...genderLabels.entries()].map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </HighlightWrapper>
      </S.FormikField>
    </>
  );
};
