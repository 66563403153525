import { Roles } from '@/domain/accounts/roles';
import { useTheme } from '@/hooks/use-theme';
import { V2Links } from '@/v2-router/const/links';
import { DateFormat } from '@i18n/date-formats';
import {
  GET_PROGRESS_REPORT_NUMERIC_METRIC_NODES_CACHE_KEY,
  getProgressReportNumericMetricNodes,
} from '@pages/content/progress-report/api/get-progress-report-numeric-metric-nodes/get-progress-report-numeric-metric-nodes.action';
import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import {
  GET_NUMERIC_METRIC_NODES_AND_META_QUERY_CACHE_KEY,
  getNumericMetricNodesAndMetaAction,
} from '@pages/content/pulse/api/get-numeric-metric-nodes-and-meta/get-numeric-metric-nodes-and-meta.action';
import { metricIdQueryParam } from '@pages/content/pulse/constants';
import { dashboardIdParam } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import EmptyGraph from '@parts/graph/parts/empty-graph/empty-graph';
import { ChartTimePeriod, Periods } from '@parts/periods/periods';
import { LazyReactApexChart } from '@parts/react-apex-chart/react-apex-chart.lazy';
import { theme as themeConfig } from '@styles/theme-config';
import { useQuery } from '@tanstack/react-query';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { slugify } from '@utils/fns/slugify';
import { useChartSeries } from '@utils/hooks/use-chart-series/use-chart-series';
import { useChartView } from '@utils/hooks/use-chart-view/use-chart-view';
import { useCurrency } from '@utils/hooks/use-currency/use-currency';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Tooltip } from 'antd';
import type { ApexOptions } from 'apexcharts';
import { stringify } from 'query-string';
import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import DES from '../../../../../dashboard-element/dashboard-element.styles';
import { ActiveSkeleton } from '../active-skeleton/active-skeleton';
import ChangeSize, { Size } from '../change-size/change-size';
import { DeleteMetric } from '../delete-modal/delete-metric';
import { DragIcon } from '../drag-icon/drag-icon';
import { MetricRibbon } from '../ribbon/ribbon.styles';
import TriggerManagement from '../trigger-management/trigger-management';
import S from './chart-metric.styles';
import { ApexTooltip } from './parts/apex-tooltip/apex-tooltip';
import { ChartSize, ChartViewDropdown, ChartViewType } from './parts/chart-view-dropdown/chart-view-dropdown';
import { NumericMetric } from './parts/numeric-metric/numeric-metric';
import { TableMetric } from './parts/table-metric/table-metric';

type MetricDisplayConfiguration = {
  dimensions: {
    height?: number | string;
    width?: number;
  };
  periods: boolean;
};

export const getMetricSymbol = (
  t: MetricType,
  config: Partial<{ currencySymbol: string; percentage: string; score: string }>,
) => {
  const { currencySymbol, percentage, score } = { currencySymbol: '$', percentage: '%', score: 'Pt', ...config };

  switch (t) {
    case MetricType.Money:
      return currencySymbol;
    case MetricType.Percentage:
      return percentage;
    case MetricType.Score:
      return score;
    default:
      return '';
  }
};

type ChartMetricProps = {
  metricId: string;
  name: string;
  triggerSet: boolean;
  timePeriod: ChartTimePeriod;
  setTimePeriod: Function;
  // TODO: During refactor probably size should be removed
  size?: ChartSize;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  chartType: ChartViewType;
  setChartType: Function;
  isMobile: boolean;
  isDesktop: boolean;
  followMode: boolean;
  reportMode: boolean;
  className?: string;
  type: MetricType;
  onRemove?: () => void;
  allowSetNotifications?: boolean;
  isZoomed?: boolean;
  dashboardId?: string;
  onSizeChange: (size: Size, preventSmall?: boolean, mediumHeight?: boolean) => void;
  isExternal: boolean;
  businessName?: string;
};

export const ChartMetric = ({
  metricId,
  name,
  size = ChartSize.DEFAULT,
  onZoomIn,
  onZoomOut,
  timePeriod,
  setTimePeriod,
  chartType,
  setChartType,
  isMobile,
  isDesktop,
  followMode,
  reportMode,
  className,
  type,
  onRemove,
  allowSetNotifications,
  isZoomed,
  dashboardId,
  onSizeChange,
  isExternal,
  businessName,
}: ChartMetricProps) => {
  const [
    provideDataLabel,
    noChartDataLabel,
    changeViewLabel,
    closePreviewLabel,
    openPreviewLabel,
    tooltipValueLabel,
    tooltipForecastLabel,
    verifiedMetricLabel,
    smallSizeMetricDisabledTooltip,
  ] = useTranslation([
    'pulse.chart.provideData',
    'pulse.chart.noData',
    'pulse.chart.changeView',
    'pulse.chart.preview.close',
    'pulse.chart.preview.open',
    'pulse.chart.tooltip.current',
    'pulse.chart.tooltip.forecast',
    'pulse.chart.tooltip.verifiedMetric',
    'pulse.chart.tooltip.smallSizeMetricDisabled',
  ]);

  const { getCurrencySymbol } = useCurrency();
  const { currencySymbol, currencyIsoCode, commaize } = useUserCurrency();
  const { dateFormatter } = useUserDateFormat();

  const symbol = getMetricSymbol(type, {
    currencySymbol: currencyIsoCode === null ? getCurrencySymbol('GBP') : currencySymbol,
  });

  const { hash = '' } = useParams<{ hash: string }>();

  const password = window.localStorage.getItem(hash);

  const { data: response, isLoading } = useQuery(
    [
      reportMode
        ? GET_PROGRESS_REPORT_NUMERIC_METRIC_NODES_CACHE_KEY(metricId)
        : GET_NUMERIC_METRIC_NODES_AND_META_QUERY_CACHE_KEY(metricId),
    ],
    reportMode
      ? getProgressReportNumericMetricNodes(hash, password, metricId)
      : getNumericMetricNodesAndMetaAction(metricId),
    { refetchOnWindowFocus: !reportMode },
  );

  const scale = response?.data?.metricMetadata?.scale || 0;

  const { series, previewSeries } = useChartSeries({
    scale,
    nodes: response?.data?.getMetricDataPoints ?? [],
  });

  const [previewValueSeries, previewForecastSeries] = previewSeries;

  const { data: previewValues } = previewValueSeries;
  const { data: previewForecasts } = previewForecastSeries;

  const { notes, currentForecast, currentValue, currentNote, max, min } = useChartView({
    scale,
    nodes: response?.data?.getMetricDataPoints ?? [],
    period: timePeriod,
  });
  const valuesPresent = previewValues.filter(([, value]) => value).length > 1;
  const forecastPresent = previewForecasts.filter(([, forecast]) => forecast).length > 1;
  const populateChart = valuesPresent || forecastPresent;

  useEffect(() => {
    if (!populateChart && typeof onZoomOut === 'function') onZoomOut();
  }, [populateChart, onZoomOut]);

  const {
    state: { userRole },
  } = useUserAccount();
  const { mode } = useTheme();

  const hasInvestorPrivileges = userRole === Roles.INVESTOR || userRole === Roles.INVESTOR_NED;

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    chart: {
      type: 'area',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
        autoSelected: 'pan',
      },
      events: {
        click: () => {
          if (isMobile) return;
          return size === ChartSize.SMALL && typeof onZoomIn === 'function' ? onZoomIn() : undefined;
        },
        scrolled: () => {
          if (timePeriod === ChartTimePeriod.YEAR_TO_DATE) setTimePeriod(ChartTimePeriod.ONE_YEAR);
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      labels: {
        show: true,
        style: {
          colors: themeConfig.color.metricFont,
          fontSize: themeConfig.fontSize.xxsmall,
          fontFamily: themeConfig.fontFamily.primary,
          fontWeight: themeConfig.fontWeight.regular,
        },
        showDuplicates: false,
        format:
          timePeriod === ChartTimePeriod.THREE_MONTHS || timePeriod === ChartTimePeriod.MAX ? "MMM 'yy" : undefined,
      },
      axisBorder: {
        show: false,
      },
      tooltip: { enabled: false },
      min,
      max,
    },
    yaxis: {
      labels: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,
        formatter: (value: number) => currencyToShortFormat(value) || '0',
      },
    },
    fill: {
      opacity: chartType === ChartViewType.BAR ? 0.5 : 0,
      gradient: {
        opacityFrom: 0,
        opacityTo: 0,
      },
      colors: [themeConfig.color.chartBlueColor, themeConfig.baseColors.Red],
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
      },
    },
    theme: { mode },
    tooltip: {
      enabled: size === ChartSize.DEFAULT,
      custom: ({ seriesIndex, dataPointIndex }: { seriesIndex: number; dataPointIndex: number }) => {
        const valueHovered = seriesIndex === 0;

        const [hoveredSeriesDate, hoveredSeriesValue] =
          valueHovered && previewValues.length ? previewValues[dataPointIndex] : previewForecasts[dataPointIndex];

        const supplementingForecast = previewForecasts.find(([date]) => date === hoveredSeriesDate);

        const supplementingSeriesItem = valueHovered
          ? supplementingForecast
          : previewValues.find(([date]) => date === hoveredSeriesDate);

        const supplementingSeriesValue = supplementingSeriesItem ? supplementingSeriesItem[1] : null;

        const noteItem = notes.find(([date]) => date === hoveredSeriesDate);

        return ReactDOMServer.renderToStaticMarkup(
          <ApexTooltip
            {...(() => {
              const note = noteItem ? noteItem[1] : null;
              const base = {
                note,
                formattedDate: hoveredSeriesDate
                  ? dateFormatter(hoveredSeriesDate, DateFormat.LETTER_MONTH_LONG_YEAR)
                  : '---',
                valueLabel: tooltipValueLabel,
                forecastLabel: tooltipForecastLabel,
              };

              const commaizedHoveredSeriesValue = hoveredSeriesValue
                ? commaize(hoveredSeriesValue, false, scale)
                : hoveredSeriesValue;

              const commaizedSupplementingSeriesValue = supplementingSeriesValue
                ? commaize(supplementingSeriesValue, false, scale)
                : supplementingSeriesValue;

              if (valueHovered) {
                return {
                  ...base,
                  value: commaizedHoveredSeriesValue,
                  forecast: commaizedSupplementingSeriesValue,
                };
              }

              return {
                ...base,
                forecast: commaizedHoveredSeriesValue,
                value: commaizedSupplementingSeriesValue,
              };
            })()}
          />,
        );
      },
    },
    markers: {
      size: [0.5, 0.5],
      colors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
      strokeColors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
      strokeWidth: 2,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
    },
    grid: {
      show: true,
      borderColor: themeConfig.color.chartBlueColor,
      strokeDashArray: 10,
      padding: {
        right: 20,
      },
    },
  };

  if (isLoading) return <ActiveSkeleton />;

  const isEditingMode = !followMode && !reportMode;
  const disabledSmallTypes = new Set([ChartViewType.BAR, ChartViewType.LINEAR, ChartViewType.TABLE]);

  const HeaderButtonsContainer = (
    <DES.MetricIcons hoverEffectDisabled={!isEditingMode}>
      {isEditingMode && !isZoomed && isDesktop && (
        <ChangeSize
          onClick={onSizeChange}
          disableSmall={disabledSmallTypes.has(chartType)}
          disableSmallTooltipText={smallSizeMetricDisabledTooltip}
          disableMedium={chartType === ChartViewType.TABLE}
          disableLarge={chartType === ChartViewType.TABLE}
        />
      )}

      {populateChart && (
        <>
          {!reportMode && !followMode && !isZoomed && (
            <Tooltip title={changeViewLabel}>
              <ChartViewDropdown
                onMenuItemSelect={(t) => {
                  setChartType(t);
                  if (isEditingMode && t === ChartViewType.TABLE) {
                    onSizeChange(Size.Large, false, true);
                  }
                  if (isEditingMode && (t === ChartViewType.LINEAR || t === ChartViewType.BAR)) {
                    onSizeChange(Size.Large, true, false);
                  }
                }}
                numericDisabled={size === ChartSize.DEFAULT}
                chartsGroup={[ChartViewType.LINEAR, ChartViewType.BAR, ChartViewType.TABLE]}
              />
            </Tooltip>
          )}
        </>
      )}

      {(() => {
        if (reportMode) return;

        if (followMode && hasInvestorPrivileges && allowSetNotifications && !isExternal) {
          return (
            <TriggerManagement
              metricId={metricId}
              scale={scale}
              currentValue={currentValue ? Number(currentValue[1]) : null}
              metricType={MetricType.Numeric}
              name={name}
              businessName={businessName}
            />
          );
        }
        if (followMode || isZoomed) return;

        return (
          <S.NavLink
            to={`${V2Links.founderMetrics({ mode: isExternal ? 'integrated-metrics' : 'metrics' })}?${stringify({
              [metricIdQueryParam]: metricId,
              [dashboardIdParam]: dashboardId,
            })}`}
            data-testid={`edit-${slugify(name)}`}
          >
            <DES.EditIcon />
          </S.NavLink>
        );
      })()}
      {onRemove && isEditingMode && !isZoomed && <DeleteMetric onRemove={onRemove} />}
      <Tooltip title={size === ChartSize.DEFAULT ? closePreviewLabel : openPreviewLabel}>
        <S.NavButton type="text" onClick={size === ChartSize.DEFAULT ? onZoomOut : undefined}>
          {size === ChartSize.DEFAULT ? <S.ZoomOutOutlined /> : null}
        </S.NavButton>
      </Tooltip>
    </DES.MetricIcons>
  );

  const numeric = (
    <NumericMetric
      date={currentValue?.[0] ? currentValue[0].toString() : null}
      note={currentNote}
      value={currentValue ? Number(currentValue[1]) : null}
      forecast={typeof currentForecast !== 'string' ? currentForecast : null}
    />
  );

  const table = (
    <TableMetric data={response?.data?.getMetricDataPoints ?? []} onClick={() => (onZoomIn ? onZoomIn() : null)} />
  );

  const content = ({ dimensions, periods }: MetricDisplayConfiguration) => {
    if (chartType === ChartViewType.NUMERIC) return numeric;
    if (chartType === ChartViewType.TABLE) return table;
    return (
      <>
        <LazyReactApexChart
          {...dimensions}
          series={previewSeries}
          type={chartType === ChartViewType.LINEAR ? 'area' : 'bar'}
          options={options}
        />
        {periods && <Periods series={series} metricId={metricId} active={timePeriod} setActive={setTimePeriod} />}
      </>
    );
  };

  const placeholder = <EmptyGraph label={followMode || reportMode ? noChartDataLabel : provideDataLabel} />;
  const metric = ({ dimensions, periods }: MetricDisplayConfiguration) =>
    populateChart ? content({ dimensions, periods }) : placeholder;

  return (
    <S.Wrapper
      size={size}
      className={className}
      zoomCursor={size === ChartSize.SMALL && chartType !== ChartViewType.NUMERIC}
      isTable={chartType === ChartViewType.TABLE}
    >
      <DES.Header>
        {isEditingMode && isDesktop && <DragIcon />}
        <Tooltip
          title={
            <S.TitleTooltipContent>
              {name} {isExternal ? <MetricRibbon /> : null}
            </S.TitleTooltipContent>
          }
        >
          <DES.Title>
            {name} {symbol && `(${symbol})`}
          </DES.Title>
        </Tooltip>
        {isExternal && (
          <Tooltip title={verifiedMetricLabel}>
            <S.TitleRibbonWrapper>
              <MetricRibbon />
            </S.TitleRibbonWrapper>
          </Tooltip>
        )}
        {HeaderButtonsContainer}
      </DES.Header>
      {metric({ dimensions: { height: '80%' }, periods: true })}
    </S.Wrapper>
  );
};
