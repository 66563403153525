import { Outlet, type RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { withLazy as withLazyLoadingComponent } from '../utils/with-lazy';

const { InternalServerErrorPage } = withLazyLoadingComponent(
  () => import('@pages/utility/internal-server/internal-server.page'),
  'InternalServerErrorPage',
);

const { Error404Page } = withLazyLoadingComponent(
  () => import('@modules/common/error/404/error-404.page'),
  'Error404Page',
);

const { Error403Page } = withLazyLoadingComponent(
  () => import('@modules/common/error/403/error-403.page'),
  'Error403Page',
);

export const errorsRoutes: RouteObject[] = [
  {
    element: <Outlet />,
    children: [
      {
        path: V2Routes.ERROR_403,
        element: <Error403Page />,
      },
      {
        path: V2Routes.ERROR_404,
        element: <Error404Page />,
      },
      {
        path: V2Routes.ERROR_500,
        element: <InternalServerErrorPage />,
      },
    ],
  },
];
