import { type RouteObject } from 'react-router-dom';
import { redirectFromLegacy } from '../const/redirect-from-legacy';
import { HandleLegacyRoute } from '../utils/handle-legacy-route';

export const generateLegacyRoutes = () =>
  Object.keys(redirectFromLegacy).map((route) => {
    return {
      path: route,
      element: <HandleLegacyRoute />,
    };
  });

export const legacyRoutes: RouteObject[] = generateLegacyRoutes();
