import { V2Links } from '@/v2-router/const/links';
import { useAuth0 } from '@auth0/auth0-react';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const REDIRECT_TO_PARAMETER_NAME = 'redirectTo';

export const LoginPage = () => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const redirectToPath =
    new URLSearchParams(window.location.search).get(REDIRECT_TO_PARAMETER_NAME) ?? V2Links.sharedStart();

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate(redirectToPath);
      return;
    }

    if (!isAuthenticated && !user && !isLoading) {
      setTimeout(() => {
        loginWithRedirect({
          authorizationParams: { screen_hint: 'signin' },
          appState: { returnTo: redirectToPath },
        });
      }, 500);
    }
  }, [isLoading, user, isAuthenticated]);

  return <FullHeightSpinner $background />;
};
