import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  width: 530px;
  max-width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing.medium} auto;
  border-radius: ${({ theme }) => theme.radius.default};
  background: ${({ theme }) => theme.baseColors.LightBlack};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default { Wrapper };
