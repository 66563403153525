import { isActiveFounder, isActiveInvestor, isActiveNed } from '@/abilities';
import { Roles } from '@/domain/accounts/roles';
import type { SubscriptionProduct } from '@/domain/accounts/subscription-products';

export const checkIsActive = (role: Roles, products: SubscriptionProduct[]) => {
  const roleMap = {
    [Roles.FOUNDER]: () => isActiveFounder(products),
    [Roles.INVESTOR]: () => isActiveInvestor(products),
    [Roles.INVESTOR_NED]: () => isActiveInvestor(products) && isActiveNed(products),
    [Roles.NED]: () => isActiveNed(products),
    [Roles.NO_ROLE]: () => true,
  };

  return roleMap[role]();
};
