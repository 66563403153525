import { V2Links } from '@/v2-router/const/links';
import type { ExternalMetric, InternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { metricIdQueryParam } from '@pages/content/pulse/constants';
import { dashboardIdParam } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import { stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';

import S from './metric-item.styles';

const MetricItem = ({
  metric,
  integratedMetricsView,
}: {
  integratedMetricsView?: boolean;
  metric: ExternalMetric | InternalMetric;
}) => {
  const { [metricIdQueryParam]: selectedMetricId, [dashboardIdParam]: dashboardId } = useQueryParams<{
    [key: string]: string;
    [dashboardIdParam]: string;
  }>();

  const [tooltipLabel, tooltipRibbonLabel] = useTranslation([
    'metrics.integratedMetrics.tooltip',
    'metrics.integratedMetrics.tooltip.ribbon',
  ]);

  const navigate = useNavigate();

  const setActiveMetric = (id: string) => {
    navigate({
      search: stringify({ [metricIdQueryParam]: id, [dashboardIdParam]: dashboardId }),
      pathname: V2Links.founderMetrics({ mode: integratedMetricsView ? 'integrated-metrics' : 'metrics' }),
    });
  };
  const isDisabled =
    metric.isExternal &&
    typeof (metric as ExternalMetric).hasDataPoints === 'boolean' &&
    !(metric as ExternalMetric).hasDataPoints;
  return (
    <S.Item
      data-testid={`metric-${metric.name.toLowerCase().replace(/\s/g, '_')}`}
      disabled={isDisabled}
      onClick={
        !isDisabled
          ? () => {
              setActiveMetric(metric.id);
              scrollToTop();
            }
          : undefined
      }
      active={metric.id === selectedMetricId}
      key={metric.id}
    >
      {isDisabled ? (
        <Tooltip title={tooltipLabel}>
          <S.Label disabled={isDisabled}>
            <span>{metric.name}</span> <S.MetricRibbon disabled={isDisabled} />
          </S.Label>
        </Tooltip>
      ) : (
        <S.Label disabled={isDisabled}>
          <span>{metric.name}</span>
          {metric.isExternal && (
            <Tooltip title={tooltipRibbonLabel}>
              <S.MetricRibbon disabled={isDisabled} />
            </Tooltip>
          )}
        </S.Label>
      )}
    </S.Item>
  );
};
export default MetricItem;
