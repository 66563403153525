import { createContext, useContext } from 'react';
import type { FormDataContext } from './form-types';

const FormContext = createContext<FormDataContext | null>(null);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) throw new Error('Form.* component must be rendered as child of Form component');

  return context;
};

export { FormContext };
