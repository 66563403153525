import { prepareConfigWithToken } from '@/utils/axios/utils/prepare-instance-with-jwt';
import { LegacyRoutes, V2Routes } from '@/v2-router/const/routes';
import { useAuth0 } from '@auth0/auth0-react';
import {
  UserAccountContext,
  userAccountInitialState,
  type UserAccountMetadata,
  type UserAccountState,
} from '@context/user/user-account.context';
import { reducer, type UserAccountAction } from '@context/user/user-account.reducer';
import { getRole } from '@domain/accounts/get-role';
import { createProductFromValue } from '@domain/accounts/subscription-products';
import { LOGIN_AS_ADMIN_STORAGE_KEY } from '@domain/admin/admin.types';
import Honeybadger from '@honeybadger-io/js';
import { ACCOUNT_DATA_CACHE_KEY, getAccountData } from '@pages/auth/api/account-data/account-data.actions';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { LoadingPage } from '@parts/loading/loading.page';
import message from '@parts/message/message';
import { useQuery } from '@tanstack/react-query';
import { isTestRunning } from '@utils/fns/is-test-running';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { tokenStorage } from '@utils/token/token-storage';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { useEffect, useMemo, useReducer, type ReactNode, type Reducer } from 'react';
import { finishLogin, finishLogout, setInitialized, setUserMetadata } from './user-account.actions';
import { handleLogoutStorageEvent } from './utils';
import { useLocale } from '../locale/locale.provider';
import { AppLocale } from '../locale/locale.context';

interface UserProviderProps {
  children: ReactNode;
}

export const isFrom = (country: CountryCodes | 'RoW', countryOfResidence: CountryCodes | null) => {
  if (country === 'RoW') {
    return countryOfResidence !== CountryCodes.GB && countryOfResidence !== CountryCodes.US;
  }
  return countryOfResidence === country;
};

const shouldSkipAuthorization = (): boolean => {
  const routesToSkip = [LegacyRoutes.REGISTER, LegacyRoutes.EMAIL_UNVERIFIED];
  return routesToSkip.some((route) => window.location.pathname.startsWith(route));
};

export const UserAccountProvider = ({ children }: UserProviderProps) => {
  const [loggingInLabel, loggingOutLabel, adminSessionExpiredLabel] = useTranslation([
    'login.loggingIn',
    'login.loggingOut',
    'admin.loginAsUser.sessionExpired',
  ]);

  const { getAccessTokenSilently, isLoading: isAuth0Loading, isAuthenticated, user } = useAuth0();
  const { updateLocale } = useLocale();

  const isAdminLoginAsUser = !isTestRunning() && window.location.pathname === V2Routes.ADMIN_LOGIN_AS_USER;
  const isAuthorized = isAdminLoginAsUser ? tokenStorage.isTokenSet() : isAuthenticated;

  const [state, dispatch] = useReducer<Reducer<UserAccountState, UserAccountAction>>(reducer, {
    ...userAccountInitialState,
    isAuthorized,
  });

  const { initialized, isLoggingIn, isLoggingOut } = state;

  const handleFinishLogin = (metadata: UserAccountMetadata) => {
    dispatch(setUserMetadata({ ...metadata }));
    dispatch(setInitialized());
    dispatch(finishLogin(metadata.userId!, metadata.userRole, metadata.products));
    Honeybadger.setContext({ userRole: metadata.userRole, userId: metadata.userId });

    // ❗️ WARNING
    // This solution works well when the application only supports English and English (US).
    // If we want to add new languages in the future, we should simplify this and use different logic.
    updateLocale(metadata.countryOfResidence === CountryCodes.US ? AppLocale.enUS : AppLocale.en);
  };

  useEffect(() => {
    const skipAuthorization = shouldSkipAuthorization();
    prepareConfigWithToken(getAccessTokenSilently, tokenStorage.isTokenSet(), skipAuthorization);
  }, [getAccessTokenSilently]);

  const { refetch: fetchAccountData } = useQuery([ACCOUNT_DATA_CACHE_KEY], getAccountData, {
    cacheTime: 60000,
    refetchOnWindowFocus: false,
    onSettled: (res) => {
      if (res?.errors?.length) {
        dispatch(setInitialized());
        dispatch(finishLogout());
        return;
      }

      if (!res?.data && !res?.data?.id) {
        return;
      }

      const {
        email,
        avatarUrl,
        permissions,
        name,
        id,
        status,
        role,
        businessName,
        integrations,
        currency,
        countryOfResidence,
        onboardingStatus,
        auth0Provider,
        group,
      } = res.data;

      const products = res.data.products.map(createProductFromValue);
      const userRole = getRole(products, role);

      const userMetadata: UserAccountMetadata = {
        name,
        businessName,
        email,
        avatarUrl,
        status,
        products,
        permissions,
        userRole,
        userId: id,
        integrations,
        currency,
        countryOfResidence,
        onboarding: onboardingStatus,
        auth0Provider,
        group,
      };

      handleFinishLogin(userMetadata);
    },
  });

  useEffect(() => {
    const isAdminSessionExpired = localStorage.getItem(LOGIN_AS_ADMIN_STORAGE_KEY);

    if (isAdminSessionExpired === 'true') {
      message.success({
        content: adminSessionExpiredLabel,
      });
      localStorage.removeItem(LOGIN_AS_ADMIN_STORAGE_KEY);
    }
  }, [adminSessionExpiredLabel]);

  useEffect(() => {
    if (isAdminLoginAsUser || !state.email) {
      return;
    }
    fetchAccountData();
  }, [state.email, state.status, fetchAccountData]);

  useEffect(() => {
    window.addEventListener('storage', handleLogoutStorageEvent);
    return () => window.removeEventListener('storage', handleLogoutStorageEvent);
  }, []);

  const isAuthLoaded =
    (!isAuth0Loading && state.isAuthorized && user) || (!isAuth0Loading && !state.isAuthorized && !user);

  const showLoader = !initialized || isLoggingIn || isLoggingOut || (!tokenStorage.isTokenSet() && !isAuthLoaded);

  useEffect(() => {
    if (isAuthLoaded) {
      dispatch(setInitialized());
    }
  }, [isAuthLoaded]);

  const loader = () => {
    if (isLoggingIn) return <LoadingPage message={loggingInLabel} />;
    if (isLoggingOut) return <LoadingPage message={loggingOutLabel} />;
    if (!isAuthLoaded) return <FullHeightSpinner $background />;
    return <FullHeightSpinner $background />;
  };

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      helpers: {
        isFrom: (targetCountry: CountryCodes | 'RoW') => isFrom(targetCountry, state.countryOfResidence),
      },
    }),
    [state, dispatch],
  );

  return (
    <UserAccountContext.Provider value={contextValue}>
      {isAdminLoginAsUser || showLoader === false ? children : loader()}
    </UserAccountContext.Provider>
  );
};
