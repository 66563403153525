import { useV2ButtonContext } from '../v2-button.context';

import S from '../v2-button.styles';

type TextProps = {
  children: React.ReactNode;
  isBold?: boolean;
  isUnderlined?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Text = ({ children, isBold = false, isUnderlined = false, ...rest }: TextProps) => {
  const { buttonSize } = useV2ButtonContext();

  return (
    <S.Text isBold={isBold} isUnderlined={isUnderlined} buttonSize={buttonSize} {...rest}>
      {children}
    </S.Text>
  );
};
