import { createContext, useContext, useMemo, useState, type Dispatch, type FC, type SetStateAction } from 'react';
import type { UnreadConversation } from 'talkjs/all';

type MessagingContextType = {
  unread: UnreadConversation[];
  unreadCount: number;
  setUnread: Dispatch<SetStateAction<UnreadConversation[]>>;
};

const MessagingContext = createContext<MessagingContextType>({
  unread: [],
  unreadCount: 0,
  setUnread: (() => {}) as Dispatch<SetStateAction<UnreadConversation[]>>,
});

export const MessagingContextProvider: FC = ({ children }) => {
  const [unread, setUnread] = useState<UnreadConversation[]>([]);

  const value = useMemo(
    () => ({
      unread,
      unreadCount: unread.reduce((acc, curr) => acc + curr.unreadMessageCount, 0),
      setUnread,
    }),
    [unread, setUnread],
  );

  return <MessagingContext.Provider value={value}>{children}</MessagingContext.Provider>;
};

export const useMessagingContext = () => {
  const context = useContext(MessagingContext);

  if (!context) throw new Error('useMessagingContext can only be used within MessagingContextProvider');

  return context;
};
