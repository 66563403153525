import { Roles } from '@/domain/accounts/roles';
import { isDebugMode } from '@/utils/fns/graphql-response-wrapper';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { V2Routes } from '@/v2-router/const/routes';

export const getRoleBasedLink = (links: Partial<Record<Roles, string>>, role: Roles) => {
  const isLinkValid = Boolean(links[role] || links['no-role']);

  if (!isLinkValid && isDebugMode()) {
    console.error('No valid link found for the user role');
  }

  return links[role] || links['no-role'] || V2Routes.ERROR_404;
};

export const useRoleBasedLink = (links: Partial<Record<Roles, string>>) => {
  const { state } = useUserAccount();

  return getRoleBasedLink(links, state.userRole);
};
