import type { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from '@utils/axios/types';

const TURNSTILE_SITE_KEY = process.env.VITE_REACT_APP_TURNSTILE_SITE_KEY;
const WIDGET_ID = 'turnstile_widget';

const appendTurnstileHtml = () => {
  if (document.getElementById(WIDGET_ID)) return;

  const div = document.createElement('div');
  div.id = WIDGET_ID;
  div.style.cssText = `
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: rgba(0,0,0,.65);
    z-index: 9999;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    `;

  const p = document.createElement('p');
  p.style.cssText = `
    color: white;
    text-align: center;
    margin-bottom: 5px;
  `;
  p.textContent = 'Please, confirm that you are a human...';

  div.appendChild(p);
  document.body.appendChild(div);
};

const showTurnstile = () => {
  const turnstileWidget = document.getElementById(WIDGET_ID);
  if (!turnstileWidget) return;

  turnstileWidget.style.pointerEvents = 'all';
  turnstileWidget.style.opacity = '1';
  turnstileWidget.style.visibility = 'visible';
  document.body.style.overflow = 'hidden';
};

const replayRequests: InternalAxiosRequestConfig[] = [];

const hideTurnstile = () => {
  const turnstileWidget = document.getElementById(WIDGET_ID);
  if (!turnstileWidget) return;

  turnstileWidget.style.pointerEvents = 'none';
  turnstileWidget.style.opacity = '0';
  turnstileWidget.style.visibility = 'hidden';
  document.body.style.overflow = 'auto';
};

const TURNSTILE_HEADER_NAME = 'cf-mitigated';
const TURNSTILE_CHALLENGE_HEADER_VALUE = 'challenge';

export const isTurnstileChallengeErrorResponse = (error: AxiosError) => {
  const challengeHeader = error?.response?.headers[TURNSTILE_HEADER_NAME];
  const turnstileChallenged = challengeHeader === TURNSTILE_CHALLENGE_HEADER_VALUE;

  return turnstileChallenged;
};

export const handleTurnstileVerification = async (error: AxiosError, instance: AxiosInstance) => {
  if (!TURNSTILE_SITE_KEY) return Promise.reject(error);

  const originalRequest = error.config;

  const turnstileChallenged = isTurnstileChallengeErrorResponse(error);
  if (turnstileChallenged) {
    if (originalRequest) {
      // if upload request is challenged, do not replay (replay will be handled by the upload component itself)
      const isUploadRequest = originalRequest.headers['Content-Type'] === 'multipart/form-data';
      if (isUploadRequest === false) {
        replayRequests.push(originalRequest);

        if (replayRequests.length > 1) {
          return;
        }
      }
    }

    appendTurnstileHtml();
    showTurnstile();

    try {
      await new Promise<void>((resolve, reject) => {
        const widgetId = turnstile.render('#turnstile_widget', {
          sitekey: TURNSTILE_SITE_KEY,
          'error-callback': function (e: Error) {
            hideTurnstile();
            reject(e);
          },
          callback: function (_token, preClearanceObtained) {
            if (preClearanceObtained) {
              // Hide the turnstile with a delay to allow the user to see the success message
              setTimeout(() => {
                hideTurnstile();
                turnstile.remove(widgetId);
              }, 150);
              return resolve();
            } else {
              return reject(new Error('Pre-clearance not obtained'));
            }
          },
        });
      });
    } catch (e) {
      return Promise.reject(e as Error);
    }

    try {
      const replays = Promise.all(replayRequests.map((request) => instance.request(request)));
      replayRequests.length = 0;
      return await replays;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  return Promise.reject(error);
};
