export const typography = {
  fonts: {
    body: 'Montserrat, sans-serif',
  },
  weights: {
    bold: 700,
    semibold: 600,
    medium: 500,
    regular: 400,
    light: 300,
  },
  sizes: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '26px',
    '4xl': '30px',
    '5xl': '36px',
  },
  lineHeights: {
    base: '120%',
    button: '140%',
  },
} as const;

export type V2Typography = typeof typography;
