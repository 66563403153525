import { isDiscoverableFounder } from '@abilities';
import { isFounderFreemium } from '@domain/accounts/subscription-products';
import { AbilityAction, AbilitySubject, type RoleAbilityBuilderFunction } from '../types';

const createActionAbilities: RoleAbilityBuilderFunction = (builder, { products }) => {
  const { can } = builder;

  if (!isFounderFreemium(products)) {
    can(AbilityAction.ACCESS, AbilitySubject.LENS_SMART_MATCH);
    can(AbilityAction.ACCESS, AbilitySubject.LENS_CONNECTIONS);
    can(AbilityAction.ACCESS, AbilitySubject.LENS_REQUESTS);
  }

  if (isDiscoverableFounder(products)) {
    can(AbilityAction.ACCESS, AbilitySubject.DISCOVER);
  }

  return builder;
};

export const createFounderAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  return createActionAbilities(builder, attributes);
};
