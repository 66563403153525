import { hasCoRSet } from '@/abilities';
import { Roles } from '@/domain/accounts/roles';
import type { SubscriptionProduct } from '@/domain/accounts/subscription-products';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { V2Routes } from '@/v2-router/const/routes';
import React, { type ComponentType } from 'react';
import { Navigate, matchPath, useLocation, useParams } from 'react-router-dom';
import { checkApiPermissions } from './helpers/check-api-permission';
import { checkIsActive } from './helpers/check-is-active';
import { hasFinishedOnboarding } from './helpers/check-onboarding';
import { checkHasRole } from './helpers/check-role';
import { getEvalPermissionFn, type PermissionStatus } from './helpers/eval-permission';
import { getRedirectUrl } from './helpers/get-redirect-link';

type Permissions = Partial<{
  roles: Roles[];
  isActive: ((role: Roles, products: SubscriptionProduct[]) => PermissionStatus) | PermissionStatus;
  isOnboard: ((role: Roles) => PermissionStatus) | PermissionStatus;
  hasCor: ((role: Roles) => PermissionStatus) | PermissionStatus;
  apiPermissions: ((role: Roles) => PermissionStatus) | PermissionStatus;
}>;

const getRouteConfigPath = (path: string): V2Routes | null => {
  return Object.values(V2Routes).find((routePath) => Boolean(matchPath(routePath, path))) ?? null;
};

export const withGuard = <P extends object>(Component: ComponentType<P>, p: Permissions): ComponentType<P> => {
  const WrappedComponent: React.FC<P> = (props) => {
    const { pathname } = useLocation();
    const { state } = useUserAccount();
    const params = useParams();
    const { userRole: role, products, onboarding, permissions: apiPermissions } = state;

    const routerConfigPath = getRouteConfigPath(pathname);
    const evalPermission = getEvalPermissionFn(role, products);

    const roleOK = p.roles ? checkHasRole(p.roles, role) : true;
    const activeOK = evalPermission(p.isActive, checkIsActive(state.userRole, products));
    const onboardingOK = evalPermission(p.isOnboard, hasFinishedOnboarding(role, onboarding));
    const corOK = evalPermission(p.hasCor, hasCoRSet(state.countryOfResidence));
    const apiPermissionsOK = evalPermission(p.apiPermissions, checkApiPermissions(apiPermissions, routerConfigPath!));

    const permissionStatus = { roleOK, activeOK, onboardingOK, corOK, apiPermissionsOK };

    const canAccessRoute = Object.values(permissionStatus).every(Boolean);
    if (canAccessRoute) return <Component {...(props as P)} />;
    return (
      <Navigate
        to={getRedirectUrl({
          role,
          route: routerConfigPath,
          params: params as { [key: string]: string },
          permissionStatus,
        })}
        replace
      />
    );
  };

  return WrappedComponent;
};
