import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import jwt from 'jsonwebtoken';
import type { Auth0Provider } from '../../../types';

export type RegistrationTokenPayload = {
  email: string;
  auth0Id: string;
  provider: Auth0Provider;
  firstName?: string;
  lastName?: string;
};

export const useAuth0RegisterToken = (): {
  token: string | null | undefined;
  isValid: boolean;
  payload: RegistrationTokenPayload | null;
} => {
  const { token } = useQueryParams();
  const decodedAuth0Token = jwt.decode(token as string) as RegistrationTokenPayload | null;

  return {
    token,
    isValid: Boolean(decodedAuth0Token),
    payload: decodedAuth0Token,
  };
};
