import { Roles } from '@/domain/accounts/roles';
import { isFounderFreemium } from '@/domain/accounts/subscription-products';
import { CountryOfResidencePage } from '@/pages/auth/country-of-residence/country-of-residence.page';
import { UnverifiedEmailPage } from '@/pages/auth/email-unverified/email-unverified.page';
import { LoginPage } from '@/pages/auth/login/login.page';
import { ChooseRolePage } from '@/pages/auth/register/auth0/choose-role/choose-role.page';
import { RedirectToRegisterPage } from '@/pages/auth/register/auth0/redirect-to-register/redirect-to-register.page';
import { RegisterFormPage } from '@/pages/auth/register/auth0/register-form/register-form.page';
import { InvitationRegistrationPage } from '@/pages/auth/register/invitation-registration/invitation-registration.page';
import { ProgressReportPage } from '@/pages/content/progress-report/progress-report.page';
import {
  CheckoutPageWithProvider as CheckoutPage,
  ExtendSubscriptionPageWithProvider as ExtendSubscriptionPage,
  UpgradeSubscriptionPageWithProvider as UpgradeSubscriptionPage,
} from '@/pages/payments/checkout/checkout-with-provider';
import { PaymentCancelledPage } from '@/pages/payments/payment-cancelled.page';
import { PaymentInProgressPage } from '@/pages/payments/payment-in-progress/payment-in-progress.page';
import { SuccessfulPaymentPage } from '@/pages/payments/successful-payment.page';
import { SplashScreenPage } from '@/pages/splash-screen/splash-screen.page';
import { Outlet, type RouteObject } from 'react-router-dom';
import { V2Routes } from '../const/routes';
import { HandleRootRoute } from '../utils/handle-root-route';
import { HandleStartRoute } from '../utils/handle-start-route';
import { withGuard } from '../utils/with-guard/with-guard';
import { LoginWithXeroPage } from '@pages/auth/xero/login-with-xero';

/**********************************************************
 *  GUARDED COMPONENTS
 */
const GuardedStartRoute = withGuard(HandleStartRoute, {
  isActive: true,
  hasCor: true,
});

const GuardedCheckoutPage = withGuard(CheckoutPage, {
  roles: [Roles.FOUNDER, Roles.NED],
  isActive: false,
  hasCor: true,
});

const GuardedPaymentInProgressPage = withGuard(PaymentInProgressPage, {
  roles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR],
  isActive: (role, products) => {
    if (role === Roles.INVESTOR) return true;
    if (role === Roles.INVESTOR_NED) return true;
    if (role === Roles.FOUNDER) {
      const canUpgrade = isFounderFreemium(products);
      return canUpgrade ? true : false;
    }

    return false;
  },
  hasCor: true,
});

const GuardedPaymentSuccessPage = withGuard(SuccessfulPaymentPage, {
  roles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR, Roles.INVESTOR_NED],
  isActive: true,
  hasCor: true,
});

const GuardedPaymentCancelledPage = withGuard(PaymentCancelledPage, {
  roles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR, Roles.INVESTOR_NED],
  isActive: false,
  hasCor: true,
});

const GuardedExtendSubscriptionPage = withGuard(ExtendSubscriptionPage, {
  roles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR],
  isActive: (role) => {
    if (role === Roles.INVESTOR) return true;
    return false;
  },
  hasCor: true,
});

const GuardedUpgradeSubscriptionPage = withGuard(UpgradeSubscriptionPage, {
  roles: [Roles.FOUNDER],
  isActive: true,
  hasCor: true,
});

const GuardedCountryOfResidencePage = withGuard(CountryOfResidencePage, {
  roles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
  hasCor: false,
});

const GuardedAwaitingApprovalPage = withGuard(SplashScreenPage, {
  roles: [Roles.INVESTOR, Roles.NED],
  isActive: false,
  hasCor: true,
  isOnboard: true,
});

export const sharedRoutes: RouteObject[] = [
  {
    element: <Outlet />,
    children: [
      /**********************************************************
       *  MAIN ROUTES
       */
      { path: V2Routes.ROOT, element: <HandleRootRoute /> },
      { path: V2Routes.SHARED_START, element: <GuardedStartRoute /> },

      /**********************************************************
       *  AUTH ROUTES
       */
      { path: V2Routes.SHARED_LOGIN, element: <LoginPage /> },
      { path: V2Routes.SHARED_REDIRECT_TO_REGISTER, element: <RedirectToRegisterPage /> },
      { path: V2Routes.SHARED_REGISTER_INVITED_FOUNDER, element: <InvitationRegistrationPage /> },
      { path: V2Routes.SHARED_REGISTER_INVITED_INVESTOR, element: <InvitationRegistrationPage /> },
      { path: V2Routes.SHARED_REGISTER, element: <ChooseRolePage /> },
      { path: V2Routes.SHARED_REGISTER_ROLE, element: <RegisterFormPage /> },
      { path: V2Routes.SHARED_EMAIL_UNVERIFIED, element: <UnverifiedEmailPage /> },
      { path: V2Routes.SHARED_LOGIN_WITH_XERO, element: <LoginWithXeroPage /> },

      /****************************************************************
       * SHARED DASHBOARD REPORT
       */
      { path: V2Routes.SHARED_DASHBOARD_REPORT, element: <ProgressReportPage /> },

      /**********************************************************
       *  PAYMENTS ROUTES
       */
      { path: V2Routes.SHARED_CHECKOUT, element: <GuardedCheckoutPage /> },
      { path: V2Routes.SHARED_PAYMENT_IN_PROGRESS, element: <GuardedPaymentInProgressPage /> },
      { path: V2Routes.SHARED_CHECKOUT_SUCCESS, element: <GuardedPaymentSuccessPage /> },
      { path: V2Routes.SHARED_CHECKOUT_CANCEL, element: <GuardedPaymentCancelledPage /> },
      { path: V2Routes.SHARED_EXTEND_PRODUCTS, element: <GuardedExtendSubscriptionPage /> },
      { path: V2Routes.SHARED_UPGRADE_PRODUCTS, element: <GuardedUpgradeSubscriptionPage /> },

      /**********************************************************
       * AWAITING ROUTES
       */
      { path: V2Routes.SHARED_AWAITING_APPROVAL, element: <GuardedAwaitingApprovalPage /> },

      /**********************************************************
       * COR SELECTION
       */
      { path: V2Routes.SHARED_COR_SELECTION, element: <GuardedCountryOfResidencePage /> },
    ],
  },
];
