import { Layout as LayoutBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(LayoutBase)`
  background: ${({ theme }) => theme.color.dashboardBackground};
  overflow: hidden;
  min-height: 100vh;
`;

export default { Wrapper };
