import type { Any } from '@/types';
import { handleTurnstileVerification } from '@/utils/turnstile/turnstile';
import { V2Links } from '@/v2-router/const/links';
import type { AxiosInstance } from 'axios';
import type { AxiosError } from '../types';

const TURNSTILE_INTERCEPTOR_RETHROW_MESSAGE = 'Turnstile verification obtained';
export const isTurnstileInterceptorSuccessResponseData = (data: Any) => {
  return data.message === TURNSTILE_INTERCEPTOR_RETHROW_MESSAGE;
};

export const turnstileInterceptor = (instance: AxiosInstance) => async (error: AxiosError) => {
  try {
    await handleTurnstileVerification(error, instance);

    throw new Error(TURNSTILE_INTERCEPTOR_RETHROW_MESSAGE);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const baseErrorInterceptor = (_instance: AxiosInstance) => async (error: AxiosError) => {
  if (
    window.localStorage.getItem('debug') === 'true' &&
    ['local', 'development', 'feature-preview', 'staging'].includes(process.env.REACT_APP_ENV)
  ) {
    return Promise.reject(error);
  }

  if (error.response?.status === 500) {
    window.location.href = V2Links.error500();
    return;
  }

  return Promise.reject(error);
};
