import { V2Links } from '@/v2-router/const/links';
import { LegacyRoutes, V2Routes } from '@/v2-router/const/routes';
import type { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import type { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { AxiosHeaders } from 'axios';
import { tokenStorage } from '../../token/token-storage';
import { axiosInstance } from '../axios-instance';
import type { AxiosConfig } from '../types';

const noRoleAccessibleRoutes: (LegacyRoutes | V2Routes)[] = [
  LegacyRoutes.LOGIN,
  LegacyRoutes.REGISTER,
  LegacyRoutes.REGISTER_INVITED_INVESTOR,
  LegacyRoutes.REGISTER_INVITED_FOUNDER,
  LegacyRoutes.REGISTER_ROLE,
  LegacyRoutes.EMAIL_UNVERIFIED,
  LegacyRoutes.REDIRECT_TO_REGISTER,
  LegacyRoutes.ERROR_403_PAGE,
  LegacyRoutes.ERROR_404_PAGE,
  LegacyRoutes.INTERNAL_SERVER_ERROR,

  V2Routes.SHARED_LOGIN,
  V2Routes.SHARED_REGISTER,
  V2Routes.SHARED_REGISTER_ROLE,
  V2Routes.SHARED_REGISTER_INVITED_FOUNDER,
  V2Routes.SHARED_REGISTER_INVITED_INVESTOR,
  V2Routes.SHARED_EMAIL_UNVERIFIED,
  V2Routes.SHARED_REDIRECT_TO_REGISTER,
  V2Routes.ERROR_403,
  V2Routes.ERROR_404,
  V2Routes.ERROR_500,
];

type GetAuth0AccessToken = {
  (
    options: GetTokenSilentlyOptions & {
      detailedResponse: true;
    },
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<string | GetTokenSilentlyVerboseResponse>;
};

const locationIsAllowed = (location: string, allowedRoutes: string[]) =>
  allowedRoutes.filter((el) => el.includes(location.split('/')[1])).length > 0;

export const prepareConfigWithToken = (
  getAccessTokenSilently: GetAuth0AccessToken,
  isTokenInMemory: boolean,
  skipAuthorization: boolean,
) => {
  // @ts-ignore
  axiosInstance.interceptors.request.use(async (config: AxiosConfig) => {
    if (config.skipAuthorization || skipAuthorization) {
      return config;
    }

    const token = isTokenInMemory ? tokenStorage.getAccessToken() : await getAccessTokenSilently();

    if (!token) {
      if (!locationIsAllowed(window.location.pathname, noRoleAccessibleRoutes)) {
        window.location.href = V2Links.sharedLogin();
      }

      return config;
    }

    return {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers,
        'X-JWT': `Bearer ${token}`,
      }),
    };
  });
};
